import { ParentState } from "@checkout/types";
import type { LloydsSessionInfo } from "@lloyds/auth";
import type { State } from "xstate";

import type { CommonMachineContext } from "../types";

type CommonCheckoutState = State<CommonMachineContext>;
type CommonCheckoutStateWithoutValue = Pick<CommonCheckoutState, "context">;

export const getSessionInfo = (
  state: CommonCheckoutStateWithoutValue
): LloydsSessionInfo =>
  state.context.sessionInfo as unknown as LloydsSessionInfo;

export const getDisabledCredits = (state: CommonCheckoutStateWithoutValue) => {
  const breakdown = state.context[ParentState.cartQuote].quoteBreakdown;
  return (
    state.context[ParentState.wallet].selectedOffer?.amount.value >
    breakdown?.productTotal.fiat.value
  );
};
