import {
  CardPaymentServices as BaseCardPaymentServices,
  CartFulfillServices as BaseCartFulfillServices,
  CartQuoteServices as BaseCartQuoteServices,
  CartUpdateServices as BaseCartUpdateServices,
  ContactInformationServices as BaseContactInformationServices,
  PassengerServices as BasePassengerServices,
  getObjectKeysAsObject,
} from "@hopper-b2b/checkout";

import { contactInformationServices } from "./contactInformation";
import { walletOffersServices } from "./walletOffers";

export const Services = {
  ...BaseCartFulfillServices,
  ...BaseCartQuoteServices,
  ...BaseCartUpdateServices,
  ...BaseContactInformationServices,
  ...BasePassengerServices,
  ...BaseCardPaymentServices,
  ...contactInformationServices,
  ...walletOffersServices,
};

export const ServiceTypes = getObjectKeysAsObject(Services);
