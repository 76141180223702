import { Route, Switch } from "react-router";
import {
  PATH_AUTH,
  PATH_AUTH_INVALID_SESSION,
  PATH_AUTH_LOGOUT,
  PATH_AUTH_START_SESSION,
  PATH_HOME,
} from "@hopper-b2b/utilities";
import { ErrorScreen } from "@lloyds/ui-connected";

import { LogoutComponent } from "./LogoutComponent";
import { StartSessionComponent } from "./StartSessionComponent";

export const AuthRoutes = () => (
  <Switch>
    <Route
      path={`${PATH_HOME}${PATH_AUTH_INVALID_SESSION}`}
      component={ErrorScreen}
    />
    <Route
      exact
      path={`${PATH_HOME}${PATH_AUTH_LOGOUT}`}
      component={LogoutComponent}
    />
    <Route
      path={`${PATH_HOME}${PATH_AUTH_START_SESSION}`}
      component={StartSessionComponent}
    />
    <Route path={`${PATH_HOME}${PATH_AUTH}*`} component={ErrorScreen} />
  </Switch>
);
