import { Fragment, useMemo } from "react";
import { useLocation } from "react-router-dom-v5-compat";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PATH_HOME, PATH_TRIPS, useDeviceTypes } from "@hopper-b2b/utilities";
import { Link } from "@lloyds/ui-core";
import {
  PATH_FAQ,
  PATH_TERMS,
  useIsBookingConfirmationPath,
} from "@lloyds/utils";
import {
  AppBar,
  Box,
  Container,
  Divider,
  Grid,
  Toolbar,
  Typography,
} from "@material-ui/core";

import styles from "./Footer.module.scss";

export const Footer = () => {
  const { matchesMobile } = useDeviceTypes();

  const { pathname } = useLocation();

  const isBookingConfirmation = useIsBookingConfirmationPath();

  const showFooter = useMemo(
    () =>
      !matchesMobile ||
      (matchesMobile &&
        (pathname === PATH_HOME ||
          pathname === PATH_TRIPS ||
          isBookingConfirmation)),
    [matchesMobile, pathname, isBookingConfirmation]
  );

  return showFooter ? <FooterInternal /> : null;
};

type FooterLink = {
  key: string;
  href: string;
  label: string;
};

const FooterInternal = () => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const year = useMemo(() => new Date().getFullYear(), []);

  const footerLinks: FooterLink[] = useMemo(
    () => [
      {
        key: "terms",
        href: PATH_TERMS,
        label: t("termsAndConditions"),
      },
      // {
      //   key: "privacy",
      //   href: "/privacy",
      //   label: t("privacyPolicy"),
      // },
      {
        key: "faq",
        href: PATH_FAQ,
        label: t("faq"),
      },
    ],
    [t]
  );

  return (
    <Box className={styles.container}>
      <AppBar component="footer" className={styles.footer}>
        <Container disableGutters maxWidth="xl" className={styles.fullHeight}>
          <Toolbar className={styles.fullHeight}>
            <Grid
              container
              direction={matchesMobile ? "column" : "row-reverse"}
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              spacing={4}
            >
              <Grid item>
                <Grid container spacing={3} alignItems="center">
                  {footerLinks.map((footerLink: FooterLink, index: number) => (
                    <Fragment key={footerLink.key}>
                      <Grid item>
                        <Link to={footerLink.href} text={footerLink.label} />
                      </Grid>
                      {!matchesMobile && index !== footerLinks.length - 1 && (
                        <Grid item>
                          <Divider classes={{ root: styles.divider }} />
                        </Grid>
                      )}
                    </Fragment>
                  ))}
                </Grid>
              </Grid>
              {matchesMobile && <Divider classes={{ root: styles.divider }} />}
              <Grid item>
                <Typography variant="caption" className={styles.legal}>
                  {`© ${year} Lloyds Bank`}
                </Typography>
              </Grid>
            </Grid>
          </Toolbar>
        </Container>
      </AppBar>
    </Box>
  );
};
