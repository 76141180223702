import { useEffect } from "react";
import { LoadingScreen } from "@lloyds/ui-connected";

export const CloseComponent = () => {
  useEffect(() => {
    window.parent.postMessage("action-complete", window.origin);
  }, []);

  return <LoadingScreen />;
};
