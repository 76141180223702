import { useCallback } from "react";
import { Button, type ButtonProps } from "@material-ui/core";
import clsx from "clsx";
import styles from "./TextButton.module.scss";

export type TextButtonProps = {
  message: string;
} & ButtonProps;

export const TextButton = ({
  message,
  className,
  color = "primary",
  variant = "text",
  size = "small",
  onClick,
  ...rest
}: TextButtonProps) => {
  const handleClick = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    },
    [onClick]
  );
  return (
    <Button
      variant={variant}
      size={size}
      className={clsx(className, styles.button)}
      onClick={handleClick}
      {...rest}
    >
      {message}
    </Button>
  );
};
