import { useDeviceTypes } from "@hopper-b2b/utilities";

import { DesktopHeader } from "./DesktopHeader";
import { MobileHeader } from "./MobileHeader";

export const Header = () => {
  const { matchesMobile } = useDeviceTypes();

  return matchesMobile ? <MobileHeader /> : <DesktopHeader />;
};
