import { useMemo } from "react";
import { type PurchaseError, PurchaseErrorEnum } from "@b2bportal/purchase-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import type { LbgErrorModalErrorProps } from "@lloyds/ui-connected";

import { useProductErrorProps } from "./useProductErrorProps";

export const usePurchaseErrorProps = (
  baseErrorProps: LbgErrorModalErrorProps,
  clearError: () => void,
  purchaseError: PurchaseError
) => {
  const { t } = useI18nContext();

  const getProductErrorProps = useProductErrorProps(baseErrorProps, clearError);

  return useMemo(() => {
    switch (purchaseError?.Error) {
      case PurchaseErrorEnum.NoAvailability:
      case PurchaseErrorEnum.ExpiredFulfillToken:
      case PurchaseErrorEnum.ExpiredQuoteToken:
        return {
          title: t("checkoutError.noAvailabilityTitle"),
          subtitle: t("checkoutError.noAvailabilitySubtitle"),
        };
      case PurchaseErrorEnum.PaymentError: // TODO: We could be more granular here in the future
        return {
          title: t("checkoutError.paymentFailedTitle"),
          subtitle: t("checkoutError.paymentFailedSubtitle"),
        };
      case PurchaseErrorEnum.ProductError:
        return getProductErrorProps(purchaseError);
      default:
        /**
          - PurchaseErrorEnum.AuthError
          - PurchaseErrorEnum.FraudAutoReject
          - PurchaseErrorEnum.InActivity
          - PurchaseErrorEnum.InvalidSession
          - PurchaseErrorEnum.NoPaymentsProvided
          - PurchaseErrorEnum.NonDistinctPayments
          - PurchaseErrorEnum.ErrorCode (because this isn't standardized enough yet)
         */
        return baseErrorProps;
    }
  }, [baseErrorProps, getProductErrorProps, purchaseError, t]);
};
