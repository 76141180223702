import { type ReactNode, useMemo, useState } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";
import {
  PATH_FLIGHTS_SEARCH,
  PATH_HOME,
  PATH_HOTELS_SEARCH,
  useDeviceTypes,
} from "@hopper-b2b/utilities";
import { ActionButton } from "@lloyds/ui-core";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";

import { DesktopFlightSearch } from "../FlightSearch";

import { DesktopHotelSearch } from "../HotelSearch";

import { SearchButton } from "./SearchButton";
import { ReactComponent as SearchIcon } from "./SearchIcon.svg";
import styles from "./TabbedSearch.module.scss";

export const HOTELS_TAB_VALUE = "hotel-tab" as const;
export const FLIGHTS_TAB_VALUE = "flights-tab" as const;

const SearchTitle = ({
  title,
  subtitle,
  isMobile,
}: {
  title: string;
  subtitle: string;
  isMobile: boolean;
}) => (
  <>
    <Box width={127} height={102} mb={5}>
      <SearchIcon height="100%" width="100%" />
    </Box>

    <Typography
      variant="inherit"
      align={isMobile ? "center" : "left"}
      className={
        isMobile ? styles.mobileSearchTitle : styles.desktopSearchTitle
      }
    >
      {title}
    </Typography>
    <Typography
      variant="inherit"
      align={isMobile ? "center" : "left"}
      className={
        isMobile ? styles.mobileSearchSubtitle : styles.desktopSearchSubtitle
      }
    >
      {subtitle}
    </Typography>
  </>
);

export const TabbedSearch = () => {
  const { t } = useI18nContext();

  const { matchesMobile } = useDeviceTypes();

  const tabs: TabType[] = useMemo(
    () =>
      [
        {
          value: HOTELS_TAB_VALUE,
          label: t?.("hotels"),
          panel: matchesMobile ? (
            <SearchButton
              path={`${PATH_HOME}${PATH_HOTELS_SEARCH}`}
              placeholder={t("hotelSearchPrompt")}
            />
          ) : (
            <DesktopHotelSearch />
          ),
        },
        {
          value: FLIGHTS_TAB_VALUE,
          label: t?.("flights"),
          panel: matchesMobile ? (
            <SearchButton
              path={`${PATH_HOME}${PATH_FLIGHTS_SEARCH}`}
              placeholder={t("flightSearchPrompt")}
            />
          ) : (
            <DesktopFlightSearch />
          ),
        },
      ].filter(Boolean),
    [matchesMobile, t]
  );

  return (
    <Box
      display="flex"
      alignItems={matchesMobile ? "center" : "flex-start"}
      flexDirection="column"
      width="100%"
      p={4}
    >
      <SearchTitle
        title={t("homepageSearchTitle")}
        subtitle={t("homepageSearchSubtitle")}
        isMobile={matchesMobile}
      />
      <Tabs
        tabClassName={clsx(
          styles.tab,
          matchesMobile ? styles.mobileTab : styles.desktopTab
        )}
        tabPanelClassName={styles.tabPanel}
        tabs={tabs}
        containerClassName={styles.tabContainer}
      />
    </Box>
  );
};

export type TabType = {
  value: string;
  label: string;
  icon?: React.ReactElement;
  panel: ReactNode;
};

export type TabsProps = {
  tabs?: TabType[];
};
function Tabs({ tabs }: TabsProps) {
  const [currentTab, setCurrentTab] = useState(tabs[0]?.value);
  const { matchesMobile } = useDeviceTypes();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gridRowGap={matchesMobile ? 12 : 52}
      width="100%"
    >
      <Box display="flex" width="100%" gridColumnGap={matchesMobile ? 16 : 0}>
        {tabs.map((tab) => {
          return matchesMobile ? (
            <ActionButton
              key={tab.value}
              message={tab.label}
              defaultStyle={
                currentTab !== tab.value ? "h4r-secondary" : "h4r-primary"
              }
              onClick={() => setCurrentTab(tab.value)}
            />
          ) : (
            <DesktopTab
              key={tab.value}
              message={tab.label}
              onClick={() => setCurrentTab(tab.value)}
              selected={currentTab === tab.value}
            />
          );
        })}
      </Box>
      {tabs.find((t) => t.value === currentTab).panel}
    </Box>
  );
}

function DesktopTab({
  message,
  onClick,
  selected,
}: {
  message: string;
  onClick: () => void;
  selected: boolean;
}) {
  return (
    <ButtonWrap
      className={clsx(
        styles.desktopTab,
        selected ? styles.desktopTabActive : ""
      )}
      onClick={onClick}
    >
      {message}
    </ButtonWrap>
  );
}
