import type { QuoteBreakdownResponse } from "@b2bportal/purchase-api";
import { CartState } from "@checkout/states";
import { ParentState } from "@checkout/types";
import { assign, type DoneInvokeEvent } from "xstate";

import type { CommonMachineContext } from "../types";

export const cartBreakdownActions = {
  setCartBreakdown: assign(
    (
      ctx: CommonMachineContext,
      event: DoneInvokeEvent<QuoteBreakdownResponse>
    ) => {
      const cartQuoteContext = ctx[CartState.cartQuote];
      cartQuoteContext.quoteBreakdown = event.data.quoteBreakdown;
      cartQuoteContext.unavailableProducts = event.data.unavailableProducts;

      const creditsContext = ctx[ParentState.credits];
      const offersContext = ctx[ParentState.wallet];

      if (creditsContext?.walletCredits) {
        const breakdownCredits = event.data.quoteBreakdown.payments.find(
          (p) => p.payment.value?.paymentType === "Credit"
        );
        if (breakdownCredits?.id) {
          creditsContext.walletCredits.id = breakdownCredits.id;
        }
      }
      if (offersContext?.selectedOffer) {
        const breakdownOffer = event.data.quoteBreakdown.payments.find(
          (p) => p.payment.value?.paymentType === "Offer"
        );
        if (breakdownOffer?.id) {
          offersContext.selectedOffer.id = breakdownOffer.id;
        }
      }
      return ctx;
    }
  ),
};
