import { LloydsTheme, LloydsVariables } from "./themes";

//  TODO: Flights
// - DONE: fix pills
// - DONE: fix mobile flight cards
// - DONE: fix desktop flight cards
// - DONE: review the review page to see if it needs touch up
// - DONE: make sure that filters are looking good on desktop flights
// - DONE: change background of calendar
// - DONE: change calendar colors to new ones
//  TODO: Mobile
// - DONE: fix new status notifications
// - DONE: decide on accents which color will it be
// - DONE: change background of bubble tags (ammenities and flights)
// - DONE: add new svg to homepage
// - DONE: fix tabbed search to be buttons
// - DONE: fix circle select component
// - DONE: fix checkbox component
// - DONE: fix filters background
// - DONE: change search icon for new one
// - DONE: rework price prediction and price protection (mobile and desktop)
// - DONE: add new dropdown select icon to select field
//  TODO: Desktop
// - DONE: change tab to new tab
// - DONE: fix date inputs to have different input for each date
// - DONE: search button at the bottom
// - DONE: fix icons on desktop search
// - DONE: fix add final icon to flights roundtrip
// - DONE: fix filter button on hotel desktop
// - DONE: refactor date button
// - fix breakpoint widths throughout the whole portal
//  TODO: Wallet:
// - DONE: change wallet svg
// - DONE: fix wallet banner to be a notification that shows up only on home page
// - DONE: review all places where wallet shows up and make the bubbles
// - DONE:bubble on hotel page
// - fix earn cashback pill
//  TODO: Hotels
//  - DONE: free cancellation pill
//  - loading colors for hotel make them grey
//  TODO: colors
// - DONE: fix background color on hotel shop decide if it will be white or grey
// - DONE: fix background colors of modals and elements that should be above things
// - change all black instances to named variables
//  TODO: header footer
// - DONE: remove header on home page
// - add long logo for desktop header
// - add horse powered by hopper to flights and hotel search
// - add horse powered by hopper to checkout header
// - rework footer
//
//  TODO: Checkout
// - DONE: change colors on row selects
// - review page (make all sections bubbles)
// - rework confirmation page
//
//  TODO: General
// - fix blur on input elements
// - refactor autocomplete component
// - refactor variables (card background and stuff specifically)
// - loading component
// - color variable for lbg modal
//
//  TODO: fintech
//  - review how much work it is to rebrand
//
//  TODO: my trips
// - everything
//
//  TODO: Fonts
// - fix font color of autocomplete results
// - fix font weights in home page
// - fix fonts of search titles both flight and hotels
// - fix font size on wallet header
//
export const createBrandStyling = () => {
  return {
    variables: LloydsVariables,
    theme: LloydsTheme,
  };
};
