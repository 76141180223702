import { useEffect, useState } from "react";
import { useRecentHotelSearches } from "@lloyds/theming";
import type { HotelSearchQueryArgs } from "@lloyds/utils";
import dayjs from "dayjs";

const HOTEL_SEARCH_KEY = "hotel-searches";
const DAYS_FORWARD = 14;
const MAX_SEARCHES = 4;

export const useHotelsRecentSearches = (
  currentSearch?: HotelSearchQueryArgs
) => {
  const [recentSearches, setRecentSearches] = useState<HotelSearchQueryArgs[]>(
    []
  );
  const enable = useRecentHotelSearches();

  useEffect(() => {
    const recentSearches = retrieveRecentSearches();
    setRecentSearches(recentSearches);
  }, []);

  return {
    recentSearches: enable ? recentSearches : [],
    filterByDestination: buildSearchFilter(currentSearch),
  };
};

const buildSearchFilter =
  (omit?: HotelSearchQueryArgs) =>
  (options: HotelSearchQueryArgs[], search: string) => {
    let internalArr = options;

    if (omit) {
      internalArr = internalArr.filter((op) => !isSameSearch(op, omit));
    }

    return internalArr.filter((op) =>
      op?.label?.toLowerCase().includes(search.toLowerCase())
    );
  };

export const retrieveRecentSearches = () => {
  try {
    const recentSearches = window.localStorage.getItem(HOTEL_SEARCH_KEY);

    if (!recentSearches) {
      return [];
    }

    const parsedRecentSearches = JSON.parse(recentSearches);

    if (!Array.isArray(parsedRecentSearches)) {
      console.warn(
        "Invalid data format for recent searches:",
        parsedRecentSearches
      );
      return [];
    } else {
      const mappedSearches = parsedRecentSearches
        .map(mapExpiredSearch)
        .slice(0, MAX_SEARCHES);
      saveRecentSearches(mappedSearches);
      return mappedSearches;
    }
  } catch (error) {
    console.error(
      "Failed to retrieve recent searches from localStorage:",
      error
    );
    return [];
  }
};

export const saveRecentSearch = (params: HotelSearchQueryArgs) => {
  try {
    const recentSearches = retrieveRecentSearches();

    const isSaved = recentSearches.some((search) =>
      isSameSearch(search, params)
    );

    if (isSaved) {
      saveRecentSearches([
        params,
        ...recentSearches.filter((p) => !isSameSearch(p, params)),
      ]);
      return;
    }

    saveRecentSearches([params, ...recentSearches.slice(0, MAX_SEARCHES - 1)]);
  } catch (error) {
    console.error("Failed to save recent search:", error);
  }
};

const saveRecentSearches = (searches: HotelSearchQueryArgs[]) => {
  try {
    window.localStorage.setItem(HOTEL_SEARCH_KEY, JSON.stringify(searches));
  } catch (error) {
    console.error("Failed to save recent searches:", error);
  }
};

const mapExpiredSearch = (search: HotelSearchQueryArgs) => {
  const today = dayjs();
  const isPastToday = dayjs(search.fromDate).isBefore(today);

  if (!isPastToday) {
    return search;
  }

  const diff = dayjs(search.untilDate).diff(dayjs(search.fromDate), "days");

  search.fromDate = today.add(DAYS_FORWARD, "days").format("YYYY-MM-DD");
  search.untilDate = today
    .add(DAYS_FORWARD + diff, "days")
    .format("YYYY-MM-DD");
  return search;
};

const isSameSearch = (
  obj1?: HotelSearchQueryArgs,
  obj2?: HotelSearchQueryArgs
) => {
  if (!obj1 || !obj2) {
    return false;
  }
  const isSameDestination = obj1?.label === obj2?.label;
  const isSameTravellerCount = countTravellers(obj1) === countTravellers(obj2);

  if (!isSameDestination || !isSameTravellerCount) {
    return false;
  }

  return obj1.fromDate === obj2.fromDate && obj1.untilDate === obj2.untilDate;
};

const countTravellers = (value: HotelSearchQueryArgs) =>
  value?.adultsCount + value?.childrenCount.length;
