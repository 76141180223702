import {
  type InitializeRumProps,
  initializeLogs,
  initializeRum,
} from "@hopper-b2b/datadog";
import { ClientName } from "@hopper-b2b/types";
import { config } from "@lloyds/utils";
import { inspect } from "@xstate/inspect";
import { createRoot } from "react-dom/client";

import { Root } from "./Root";
import "./cfar-overrides.scss";
import "./global-overrides.scss";
import "./index.scss";
import "./lodging-overrides.scss";
import { getSessionId, maybeLoadMocks } from "./utils";

process.env.NODE_ENV !== "production" &&
  inspect({
    iframe: false,
  });

const sessionId = getSessionId();

// Initialize Datadog Logs
initializeLogs({
  sessionId,
  env: config.env,
  version: config.version,
  service: ClientName.LLOYDS,
});

// Initialize Datadog RUM
initializeRum({
  env: config.env,
  version: config.version,
  service: ClientName.LLOYDS,
  enableSessionRecording: true,
  allowedTracingUrls: [
    // Internal
    "http://localhost:3003",
    "https://stallion.staging.hopper.com",
    // Build
    "https://stallion-build.staging.hopper.com",
    // Int
    "stallion-int.staging.hopper.com",
    "travel.sit01-mlp-apis-b.digital.lloydsbank.co.uk",
    "travel.sit01-mlp-apis-w.digital.lloydsbank.co.uk",
    "travel.sit01-dlp-secure-w.digital.lloydsbank.co.uk",
    "travel.sit02-dlp-secure-w.digital.lloydsbank.co.uk",
    "travel.sit02-mlp-apis-w.digital.lloydsbank.co.uk",
    "travel.sit02-mlp-apis-b.digital.lloydsbank.co.uk",
    "travel.sit02-dlp-secure-b.digital.lloydsbank.co.uk",
    "travel.sit01-dlp-secure-b.digital.lloydsbank.co.uk",
    // Pre-prod
    "stallion-pre.staging.hopper.com",
    "travel.nft01-mlp-apis-b.digital.lloydsbank.co.uk",
    "travel.nft01-mlp-apis-w.digital.lloydsbank.co.uk",
    "travel.nft02-mlp-apis-b.digital.lloydsbank.co.uk",
    "travel.nft02-dlp-secure-b.digital.lloydsbank.co.uk",
    "travel.nft01-dlp-secure-w.digital.lloydsbank.co.uk",
    "travel.nft01-dlp-secure-b.digital.lloydsbank.co.uk",
    // LUAT
    "travel.luat01-mlp-apis-rk-b.digital.lloydsbank.co.uk",
    "travel.luat01-mlp-apis-rk-w.digital.lloydsbank.co.uk",
    "travel.luat02-mlp-apis-p2-b.digital.lloydsbank.co.uk",
    "travel.luat02-mlp-apis-p2-w.digital.lloydsbank.co.uk",
    "travel.luat03-mlp-apis-rk-b.digital.lloydsbank.co.uk",
    "travel.luat03-mlp-apis-rk-w.digital.lloydsbank.co.uk",
    "travel.luat04-mlp-apis-p2-b.digital.lloydsbank.co.uk",
    "travel.luat04-mlp-apis-p2-w.digital.lloydsbank.co.uk",
    "travel.mlp-apis-p2-l1-b.digital.lloydsbank.co.uk",
    "travel.mlp-apis-p2-l1-w.digital.lloydsbank.co.uk",
    "travel.mlp-apis-rk-l1-b.digital.lloydsbank.co.uk",
    "travel.mlp-apis-rk-l1-w.digital.lloydsbank.co.uk",
    "travel.mlp-apis-p2-l2-b.digital.lloydsbank.co.uk",
    "travel.mlp-apis-p2-l2-w.digital.lloydsbank.co.uk",
    "travel.mlp-apis-rk-l2-b.digital.lloydsbank.co.uk",
    "travel.mlp-apis-rk-l2-w.digital.lloydsbank.co.uk",
    "travel.dlp-secure-rk-l2-w.digital.lloydsbank.co.uk",
    "travel.dlp-secure-rk-l2-b.digital.lloydsbank.co.uk",
    "travel.dlp-secure-p2-l2-w.digital.lloydsbank.co.uk",
    "travel.dlp-secure-p2-l2-b.digital.lloydsbank.co.uk",
    "travel.dlp-secure-rk-l1-w.digital.lloydsbank.co.uk",
    "travel.dlp-secure-rk-l1-b.digital.lloydsbank.co.uk",
    "travel.dlp-secure-p2-l1-w.digital.lloydsbank.co.uk",
    "travel.dlp-secure-p2-l1-b.digital.lloydsbank.co.uk",
    "travel.luat04-dlp-secure-p2-w.digital.lloydsbank.co.uk",
    "travel.luat04-dlp-secure-p2-b.digital.lloydsbank.co.uk",
    "travel.luat03-dlp-secure-rk-w.digital.lloydsbank.co.uk",
    "travel.luat03-dlp-secure-rk-b.digital.lloydsbank.co.uk",
    "travel.luat02-dlp-secure-p2-w.digital.lloydsbank.co.uk",
    "travel.luat02-dlp-secure-p2-b.digital.lloydsbank.co.uk",
    "travel.luat01-dlp-secure-rk-w.digital.lloydsbank.co.uk",
    "travel.luat01-dlp-secure-rk-b.digital.lloydsbank.co.uk",
    "travel-hopper.apis.lloydsbank.co.uk",
    // Prod
    "stallion.hopper.com",
    "travel-hopper.secure.lloydsbank.co.uk",
  ],
} as InitializeRumProps);

const renderRoot = () => {
  const container = document.getElementById("root");
  const root = createRoot(container);
  root.render(<Root />);
};

maybeLoadMocks().then(renderRoot);
