import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { PATH_HOME } from "@hopper-b2b/utilities";
import { LoadingScreen } from "@lloyds/ui-connected";

export const LogoutComponent = () => {
  const navigate = useNavigate();

  const logout = useCallback(() => {
    try {
      navigate(PATH_HOME);
    } catch (err) {
      console.log(err);
    }
  }, [navigate]);

  useEffect(() => {
    logout();
  }, [logout]);

  return <LoadingScreen />;
};
