import { Link } from "react-router-dom-v5-compat";
import { useI18nContext } from "@hopper-b2b/i18n";
import { PATH_HOME } from "@hopper-b2b/utilities";
import { Logo as LogoAsset } from "@lloyds/assets";
import { useUserSessionBrand } from "@lloyds/auth";

export const HEADER_LOGO_TEST_ID = "header-logo-test-id";

export const HeaderLogo = () => {
  const { t } = useI18nContext();
  const brand = useUserSessionBrand();

  return (
    <Link to={PATH_HOME} aria-label={t("home")}>
      <LogoAsset id={HEADER_LOGO_TEST_ID} brand={brand} aria-hidden="true" />
    </Link>
  );
};
