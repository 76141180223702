import { useCallback, useMemo, useState } from "react";
import type { AmenityStatus } from "@b2bportal/lodging-api";
import { CheckboxGroup } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import type { LodgingsFilterBoundaries } from "@hopper-b2b/lodging-availability";
import { TextButton } from "@lloyds/ui-core";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import styles from "./AmenitiesFilter.module.scss";

const MAX_AMENITY_FILTERS = 10;

export function AmenitiesFilter({
  amenities,
  andFilter,
  appliedFilters,
  onChange,
  onTypeChange,
  sortAmenities,
}: {
  amenities: LodgingsFilterBoundaries["amenities"];
  andFilter: boolean;
  appliedFilters: string[];
  onChange: (amenities: string[]) => void;
  onTypeChange: (value: boolean) => void;
  sortAmenities?: (amenities: AmenityStatus[]) => AmenityStatus[];
}) {
  const { t } = useI18nContext();
  const [showMore, setShowMore] = useState(false);

  const sortedAmenities = useMemo(() => {
    const arr: AmenityStatus[] = [];

    amenities.forEach((item) => arr.push(item.amenity));

    const sorted = sortAmenities
      ? sortAmenities(arr).sort((a, b) => {
          const aApp = appliedFilters.includes(a.amenity);
          const bApp = appliedFilters.includes(b.amenity);
          return aApp && bApp ? 0 : aApp ? -1 : 1;
        })
      : arr;
    return sorted
      .slice(0, showMore ? -1 : MAX_AMENITY_FILTERS)
      .map((amenity) => ({
        value: amenity.amenity,
        label: amenity.displayName,
      }));
  }, [sortAmenities, amenities, appliedFilters, showMore]);

  const handleShowMoreClick = useCallback(() => {
    setShowMore(!showMore);
  }, [showMore]);

  return (
    <>
      <CheckboxGroup
        options={[{ value: "1", label: t("includesAllSelectedAmenities") }]}
        checkedOptions={andFilter ? ["1"] : []}
        onOptionsChange={(values) => {
          onTypeChange(values.includes("1"));
        }}
      />
      <div className={styles.AmenitiesGrid}>
        <CheckboxGroup
          options={sortedAmenities}
          checkedOptions={appliedFilters}
          onOptionsChange={onChange}
        />
      </div>
      <TextButton
        onClick={handleShowMoreClick}
        message={!showMore ? t("showMore") : t("showLess")}
      />
    </>
  );
}
