import type { GetVouchersResponseSuccess } from "@b2bportal/lloyds-wallet-api";
import { ParentState } from "@checkout/types";
import { OfferPaymentType } from "@hopper-b2b/types";
import { assign, type DoneInvokeEvent } from "xstate";

import type { CommonMachineContext } from "../types";

import { discountAmount } from "../utilities";

export const walletOfferActions = {
  handleGetWalletOffers: assign(
    (
      context: CommonMachineContext,
      event: DoneInvokeEvent<GetVouchersResponseSuccess>
    ) => {
      const breakdown = context[ParentState.cartQuote].quoteBreakdown;

      const offers = event.data.offers;
      context[ParentState.wallet].fetchedOffers = true;
      if (offers.length > 0) {
        context[ParentState.wallet].offers = offers.map((offer) => {
          const discount = breakdown?.productTotal
            ? discountAmount(breakdown.productTotal.fiat, offer)
            : offer.amount;
          return {
            ...offer,
            amount: {
              ...offer.amount,
              value: Math.abs(discount.value) * -1,
            },
          };
        });
        context[ParentState.wallet].selectedOffer = offers.find(
          (o) => o.payment.value?.paymentType === OfferPaymentType.Offer
        );
      }

      return context;
    }
  ),
};
