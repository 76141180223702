import { useCallback } from "react";
import { FlightError } from "@b2bportal/air-booking-api";
import { useCheckoutSend } from "@checkout/context";
import { useI18nContext } from "@hopper-b2b/i18n";

import { Event, type TEvent } from "../events";

import { useFlightTravelerErrorProps } from "./useFlightTravelerErrorProps";

export const useFlightErrorProps = (baseErrorProps, clearError: () => void) => {
  const { t } = useI18nContext();
  const send = useCheckoutSend<TEvent>();

  const handleBackToContactInformation = useCallback(() => {
    clearError();
    send(Event.GO_TO_CONTACT_INFORMATION);
  }, [clearError, send]);

  const goToConfirmation = useCallback(() => {
    clearError();
    send(Event.GO_TO_BOOKING_CONFIRMATION);
  }, [clearError, send]);

  const getFlightTravelerErrorProps = useFlightTravelerErrorProps(clearError);

  return useCallback(
    (flightError: FlightError) => {
      switch (flightError) {
        case FlightError.DuplicateBooking:
          return {
            title: t("checkoutError.duplicateBookingTitle"),
            subtitle: t("checkoutError.duplicateBookingSubtitle"),
            primaryButtonText: t("checkoutError.acknowlegement"),
            primaryOnClick: goToConfirmation,
          };
        case FlightError.TicketingNotStarted:
          return {
            title: t("checkoutError.ticketingNotStartedTitle"),
            subtitle: t("checkoutError.ticketingNotStartedSubtitle"),
            primaryButtonText: t("checkoutError.acknowlegement"),
            primaryOnClick: goToConfirmation,
          };
        case FlightError.TicketingUnconfirmed:
          return {
            title: t("checkoutError.ticketingUnconfirmedTitle"),
            subtitle: t("checkoutError.ticketingUnconfirmedSubtitle"),
            primaryButtonText: t("checkoutError.acknowlegement"),
            primaryOnClick: goToConfirmation,
          };
        case FlightError.TicketingUnknown:
          return {
            title: t("checkoutError.ticketingUnknownTitle"),
            subtitle: t("checkoutError.ticketingUnknownSubtitle"),
            primaryButtonText: t("checkoutError.acknowlegement"),
            primaryOnClick: goToConfirmation,
          };
        case FlightError.NoContactInformation:
          return {
            title: t("checkoutError.noContactInformationTitle"),
            subtitle: t("checkoutError.noContactInformationSubtitle"),
            primaryButtonText: t("checkoutError.updateContactInformation"),
            primaryOnClick: handleBackToContactInformation,
          };
        case FlightError.Inactivity:
          // inactivity here means that the flight booking session timed out
          return {
            title: t("checkoutError.flightsInActivityTitle"),
            subtitle: t("checkoutError.flightsInActivitySubtitle"),
            ...baseErrorProps,
          };
        case FlightError.MissingAirlineLocator:
          return {
            title: t("checkoutError.missingAirlineLocatorTitle"),
            subtitle: t("checkoutError.missingAirlineLocatorSubtitle"),
            ...baseErrorProps,
          };

        case FlightError.NoTicketlessResponse:
          return {
            title: t("checkoutError.noTicketlessResponseTitle"),
            subtitle: t("checkoutError.noTicketlessResponseSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.ProviderError:
          return {
            title: t("checkoutError.providerErrorTitle"),
            subtitle: t("checkoutError.providerErrorSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.TransientServiceError:
          return {
            title: t("checkoutError.transientServiceErrorTitle"),
            subtitle: t("checkoutError.transientServiceErrorSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.UnknownSabreAppError:
          return {
            title: t("checkoutError.unknownSabreAppErrorTitle"),
            subtitle: t("checkoutError.unknownSabreAppErrorSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.UpgradeRequired:
          return {
            title: t("checkoutError.upgradeRequiredTitle"),
            subtitle: t("checkoutError.unknownSabreAppErrorSubtitle"),
            ...baseErrorProps,
          };
        case FlightError.NoAvailability:
          return {
            title: t("checkoutError.noAvailabilityTitle"),
            subtitle: t("checkoutError.noAvailabilitySubtitle"),
            ...baseErrorProps,
          };
        // Traveler Errors
        case FlightError.CheckInMinimumAgeNotMet:
        case FlightError.ExpiredPassport:
        case FlightError.IllegalLapInfantKTN:
        case FlightError.IllegalLapInfantRedress:
        case FlightError.InvalidCustomer:
        case FlightError.InvalidPassengers:
        case FlightError.InvalidPassportNumber:
        case FlightError.LapInfantTooOld:
        case FlightError.LapInfantsUnsupported:
        case FlightError.MalformedKnownTravelerNumber:
        case FlightError.MalformedRedressNumber:
        case FlightError.MissingNationality:
        case FlightError.MissingPassport:
        case FlightError.MissingPassportNumber:
        case FlightError.NoAdultPassenger:
        case FlightError.SeatedInfantsUnsupported:
        case FlightError.TooManyLapInfants:
        case FlightError.TooManyPassengers:
          return getFlightTravelerErrorProps(flightError) ?? baseErrorProps;

        default:
          // default cover FlightErrors that should not occur in PriceQuote
          return baseErrorProps;
      }
    },
    [
      baseErrorProps,
      getFlightTravelerErrorProps,
      goToConfirmation,
      handleBackToContactInformation,
      t,
    ]
  );
};
