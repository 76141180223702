import { useI18nContext } from "@hopper-b2b/i18n";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, Typography } from "@material-ui/core";

import { DesktopHotelSearch } from "./DesktopHotelSearch";
import { MobileHotelSearch } from "./MobileHotelSearch";

export const HotelSearch = () => {
  const { matchesMobile } = useDeviceTypes();

  const { t } = useI18nContext();

  return !matchesMobile ? (
    <DesktopHotelSearch />
  ) : (
    <Box>
      <Box marginBottom={4} marginTop={4}>
        <Typography variant="h3">
          {t("lodging.search.selectDestinationTitle")}
        </Typography>
      </Box>

      <MobileHotelSearch />
    </Box>
  );
};
