import { useCallback, useMemo } from "react";
import type { ITripTerminus } from "@b2bportal/core-types";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { URL_DATE_FORMAT, useOnMount } from "@hopper-b2b/utilities";
import { DiagonalPlane } from "@lloyds/theming";
import { Box } from "@material-ui/core";
import dayjs from "dayjs";

import { CalendarPicker } from "./CalendarPicker";
import type { LloydsCalendarPickerProps } from "./types";

export type FlightCalendarPickerProps = LloydsCalendarPickerProps & {
  destination?: ITripTerminus;
  origin?: ITripTerminus;
};

export const FlightCalendarPicker = ({
  destination,
  origin,
  tripCategory,
  startDate: departureDate,
  endDate: returnDate,
  setStartDate: setDepartureDate,
  setEndDate: setReturnDate,
  priceTags,
  months,
  onComplete,
}: FlightCalendarPickerProps) => {
  const { t } = useI18nContext();

  const trackingProperties = useMemo(
    () => ({
      destination: destination?.id?.code?.code ?? "",
      origin: origin?.id?.code?.code ?? "",
      tripCategory,
    }),
    [destination, origin, tripCategory]
  );

  useOnMount(() => {
    trackEvent({
      eventName: "view_calendar",
      properties: trackingProperties,
    });
  });

  const handleDepartureDateChange = useCallback(
    (date: Date) => {
      const formatedDate = dayjs(date).format(URL_DATE_FORMAT);
      if (date) {
        setDepartureDate(formatedDate);
        if (departureDate) {
          setReturnDate(null);
        }
      }
    },
    [departureDate, setDepartureDate, setReturnDate]
  );

  const handleReturnDateChange = useCallback(
    (date: Date) => {
      const formatedDate = dayjs(date).format(URL_DATE_FORMAT);
      if (date) {
        setReturnDate(formatedDate);
      }
    },
    [setReturnDate]
  );

  const onCompleteInternal = useCallback(() => {
    onComplete();
  }, [onComplete]);

  return (
    <CalendarPicker
      tripCategory={tripCategory}
      startDate={departureDate ? dayjs(departureDate).toDate() : null}
      endDate={returnDate ? dayjs(returnDate).toDate() : null}
      startDateLabel={t("departure")}
      endDateLabel={t("return")}
      warningText={
        !departureDate
          ? t("flight.calendar.selectDepartureDate")
          : t("flight.calendar.selectReturnDate")
      }
      startDateIcon={<DepartureIcon ready={!!departureDate} />}
      endDateIcon={<ReturnIcon ready={!!returnDate} />}
      setStartDate={handleDepartureDateChange}
      setEndDate={handleReturnDateChange}
      onComplete={onCompleteInternal}
      priceTags={priceTags}
      months={months}
    />
  );
};
const DepartureIcon = ({ ready }: { ready?: boolean }) => {
  return (
    <DiagonalPlane
      style={{
        transform: "rotate(90deg)",
        paddingTop: "10px",
        color: ready ? "var(--text-primary)" : "var(--text-subdued)",
        height: "34px",
        width: "34px",
        fill: ready ? "var(--text-primary)" : "var(--text-subdued)",
      }}
    />
  );
};
const ReturnIcon = ({ ready }: { ready?: boolean }) => {
  return (
    <Box>
      <DiagonalPlane
        style={{
          paddingRight: "10px",
          color: ready ? "var(--text-primary)" : "var(--text-subdued)",
          height: "24px",
          width: "34px",
          fill: ready ? "var(--text-primary)" : "var(--text-subdued)",
        }}
      />
    </Box>
  );
};
