import { ParentState } from "@hopper-b2b/checkout";
import type * as H from "history";
import queryStringParser from "query-string";
import type { StateValue, send as xstateSend } from "xstate";

import { CheckoutQueryParams } from "../types";

/**
 *  Stringifies first StateValue to add to url params
 * @example
 * stringifyParentStateValue({ passengerInfo: { picker: "idle" } });
 * returns "passengerInfo"
 */
export const stringifyParentStateValue = (state: StateValue) => {
  if (typeof state === "string") return state;
  else if (typeof state === "object") {
    const key = Object.keys(state)[0];
    return key;
  } else {
    return "";
  }
};

const untrackedStates = [
  ParentState.loading,
  ParentState.auth,
  ParentState.cartQuote,
  ParentState.cartUpdate,
  ParentState.cartFulfill,
  ParentState.wallet,
];

// Map of states that should not transition when query params change
// Map: { state that should not transition => previous tracked state }
// Eg: if user is in "cartQuote" and hits back on their browser, the user should not be allowed to go back 1 step and
// instead stay in the current step till it resolves
const preventTransitionStates = {
  [ParentState.cartQuote]: ParentState.passengerInformation,
  [ParentState.cartUpdate]: ParentState.passengerInformation,
  [ParentState.cartUpdateBeforeFulfill]: ParentState.review,
  [ParentState.cartFulfill]: ParentState.review,
};

export const populateCheckoutQueryParams = (
  state: StateValue,
  history: H.History
) => {
  const queryString = history?.location?.search || "";
  const parsedQuery = queryStringParser.parse(queryString);
  const stateQueryParam = parsedQuery?.[CheckoutQueryParams.checkoutState];

  const stringifiedNewState = stringifyParentStateValue(state);

  const shouldUpdateQuery = !untrackedStates.includes(
    stringifiedNewState as ParentState
  );

  //  If xstate state value is not in query params or if it conflicts with what the
  //  current query params contains, then we push new query params to the URL

  const historyData = {
    pathname: history.location.pathname,
    search: queryStringParser.stringify({
      ...parsedQuery,
      [CheckoutQueryParams.checkoutState]: stringifiedNewState,
    }),
    state: history.location.state,
  };

  if (!stateQueryParam && shouldUpdateQuery) {
    history.replace(historyData);
  } else if (
    stateQueryParam &&
    stringifiedNewState !== stateQueryParam &&
    shouldUpdateQuery
  ) {
    history.push(historyData);
  }
};

export const transitionStateOnPathnameChange =
  (mapStateToTransitionEvent) =>
  (state: StateValue, history: H.History, send: typeof xstateSend) => {
    const queryString = history.location.search;
    const parsedQuery = queryStringParser.parse(queryString);
    const stateQueryParam = parsedQuery?.[CheckoutQueryParams.checkoutState];

    const stringifiedNewState = stringifyParentStateValue(state);

    const preventTransition = !!preventTransitionStates[stringifiedNewState];

    const allowTransition =
      !preventTransition &&
      stateQueryParam &&
      !untrackedStates.includes(stateQueryParam as ParentState);

    const hasSucceededFulfillment =
      stringifiedNewState === ParentState.bookingConfirmation;

    if (
      stateQueryParam &&
      stringifiedNewState !== stateQueryParam &&
      allowTransition
    ) {
      if (hasSucceededFulfillment) {
        handleFulfillSuccess(history);
      } else {
        send({
          type: mapStateToTransitionEvent[stateQueryParam as ParentState],
        });
      }
    } else if (
      preventTransition &&
      preventTransitionStates[stringifiedNewState] !== stateQueryParam
    ) {
      const historyData = {
        pathname: history.location.pathname,
        search: queryStringParser.stringify({
          ...parsedQuery,
          [CheckoutQueryParams.checkoutState]:
            preventTransitionStates[stringifiedNewState],
        }),
        state: history.location.state,
      };
      // Pushing the same stack that was removed when the user goes 1 step back in their browser
      history.push(historyData);
    }
  };

const handleFulfillSuccess = (history: H.History) => {
  // TODO: Use var instead of hardcode + check with product
  history.push("/");
};
