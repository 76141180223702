import { type ReactNode, useCallback } from "react";
import { Checkbox } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { RadioInput, TextButton } from "@lloyds/ui-core";
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import clsx from "clsx";

import { DeleteButton, type DeleteButtonProps } from "../DeleteButton";

import styles from "./SelectionRow.module.scss";

export const enum SelectionType {
  RADIO,
  CHECK,
}

export type SelectionRowProps = {
  title: string | ReactNode;
  description?: string | ReactNode;

  selected?: boolean;
  disabled?: boolean;

  onSelect: () => void;
  onEdit?: () => void;

  deleteButtonProps?: DeleteButtonProps;

  type: SelectionType;

  ariaLabel?: string;
};

export const SelectionRow = ({
  title,
  description,
  selected,
  disabled,
  onSelect,
  onEdit,
  deleteButtonProps: {
    onClick: onDelete,
    ...restDeleteButtonProps
  } = {} as DeleteButtonProps,
  type,
  ariaLabel,
}: SelectionRowProps) => {
  const { t } = useI18nContext();

  const handleEdit = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onEdit?.();
    },
    [onEdit]
  );

  const handleDelete = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onDelete?.(e);
    },
    [onDelete]
  );

  return (
    <Card
      className={clsx(styles.card, {
        [styles.selectedCard]: selected,
        [styles.disabledCard]: disabled,
      })}
      onClick={disabled ? null : onSelect}
    >
      <CardContent className={styles.content}>
        <Grid container spacing={3} direction="row" alignItems="center">
          <Grid item>
            <SelectionInput
              type={type}
              selected={selected}
              disabled={disabled}
            />
          </Grid>
          <Grid container direction="column" spacing={1} item xs>
            <Typography variant="inherit" className={styles.title}>
              {title}
            </Typography>
            {description && (
              <Typography
                variant="body2"
                className={styles.subtitle}
                color="textSecondary"
              >
                {description}
              </Typography>
            )}
          </Grid>
          {!disabled && (
            <Grid item>
              <Grid container alignItems="center">
                {onEdit && (
                  <Grid item>
                    <TextButton
                      color="primary"
                      onClick={handleEdit}
                      aria-label={t("editTraveler")}
                      message={t("edit")}
                    />
                  </Grid>
                )}
                {onEdit && onDelete && (
                  <Divider
                    orientation="vertical"
                    flexItem
                    style={{ marginTop: 8, marginBottom: 8 }}
                  />
                )}
                {onDelete && (
                  <Grid item>
                    <DeleteButton
                      variant="text"
                      color="primary"
                      onClick={handleDelete}
                      {...restDeleteButtonProps}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

const SelectionInput = ({
  type,
  selected,
  disabled,
}: {
  type: SelectionType;
  selected?: boolean;
  disabled?: boolean;
}) => {
  switch (type) {
    case SelectionType.RADIO: {
      return <RadioInput checked={selected} disabled={disabled} />;
    }
    case SelectionType.CHECK: {
      return <Checkbox checked={selected} disabled={disabled} />;
    }
  }
};
