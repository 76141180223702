import type {
  CartContext,
  ParentState,
  PartialParentContext,
} from "@hopper-b2b/checkout";

import type { WalletCreditsContext } from "./states";

export interface CommonMachineContext
  extends PartialParentContext,
    CartContext {
  [ParentState.credits]: WalletCreditsContext;
}

export enum CheckoutQueryParams {
  checkoutState = "checkoutState",
}
