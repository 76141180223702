import { useCallback, useEffect, useMemo, useState } from "react";
import { IconNameEnum } from "@b2bportal/core-themes";
import { Icon } from "@components/ui";
import {
  commonSelectors,
  getParentState,
  ParentState,
  useCheckoutSend,
  useCheckoutStateSelector as useSelector,
  WalletSelectors,
} from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";

import type { WalletOffer } from "@hopper-b2b/types";
import {
  Event,
  getDisabledCredits,
  getWalletCredit,
  type TEvent,
} from "@lloyds/common-checkout";
import {
  TextInputField,
  SelectionRow,
  SelectionType,
} from "@lloyds/ui-connected";

import { ActionButton, StatusMessage } from "@lloyds/ui-core";
import { Divider, Typography } from "@material-ui/core";

import styles from "./PaymentWallet.module.scss";

export const PaymentWallet = () => {
  const { t, formatFiatCurrency } = useI18nContext();
  const send = useCheckoutSend<TEvent>();

  const selectedCredit = useSelector(getWalletCredit);
  const selectedOffer = useSelector(WalletSelectors.getWalletOffer);
  const offers = useSelector(WalletSelectors.getWalletOffers);
  const disabledCredits = useSelector(getDisabledCredits);

  const stateValue = useSelector(commonSelectors.getStateValue) as string;
  const parentState = getParentState(stateValue);
  const isCurrentState = parentState === ParentState.cardPayment;

  const [credits, setCredits] = useState<number | undefined>(undefined);

  useEffect(() => {
    setCredits(selectedCredit?.amount.value);
  }, [setCredits, selectedCredit]);

  const walletOffers = useMemo(() => {
    return offers?.filter((o) => o.payment.value.paymentType === "Offer");
  }, [offers]);

  const creditOffer = useMemo(() => {
    return offers?.find((o) => o.payment.value.paymentType === "Credit");
  }, [offers]);

  const onCreditsSelected = useCallback(() => {
    send({
      type: Event.SET_WALLET_CREDITS,
      credits: {
        ...creditOffer,
        amount: {
          ...creditOffer.amount,
          value: credits,
        },
      },
    });
  }, [send, creditOffer, credits]);

  const onCreditsRemoved = useCallback(() => {
    send(Event.REMOVE_WALLET_CREDITS);
  }, [send]);

  const onOfferSelected = useCallback(
    (offer: WalletOffer) => {
      send({
        type: Event.SET_SELECTED_OFFER,
        offerId: offer.id,
      });
      if (selectedCredit) {
        onCreditsRemoved();
      }
    },
    [send, onCreditsRemoved, selectedCredit]
  );

  const onOfferRemoved = useCallback(() => {
    if (selectedOffer) {
      send({
        type: Event.REMOVE_SELECTED_OFFER,
        offerId: selectedOffer.id,
      });
    }
  }, [send, selectedOffer]);

  const creditsErrorMessage = useMemo(() => {
    if (creditOffer && credits) {
      const creditAmount = Math.abs(creditOffer.amount.value);
      return credits > creditAmount ? t("creditsError") : undefined;
    }
    return undefined;
  }, [credits, creditOffer, t]);

  const disabled = !isCurrentState;

  return (
    <>
      {walletOffers?.length > 0 ? (
        <div className={styles.travelOffersSection}>
          <Typography variant="h5">{t("applyOffer")}</Typography>
          <Typography variant="subtitle1">{t("selectOfferTitle")}</Typography>
          <div className={styles.offers}>
            {walletOffers.map((offer) => {
              const absDiscount = {
                ...offer.amount,
                value: Math.abs(offer.amount.value),
              };
              return (
                <SelectionRow
                  key={offer.id}
                  type={SelectionType.RADIO}
                  title={`${t("applyOffer")}:`}
                  description={
                    <Typography>
                      {t("wallet.discount", {
                        amount: formatFiatCurrency(absDiscount),
                      })}
                    </Typography>
                  }
                  selected={selectedOffer?.id === offer.id}
                  disabled={disabled}
                  onSelect={() => onOfferSelected(offer)}
                  ariaLabel={t("selectVoucher")}
                />
              );
            })}
            <SelectionRow
              type={SelectionType.RADIO}
              title={t("declineOffer")}
              description={null}
              selected={!selectedOffer}
              disabled={disabled}
              onSelect={onOfferRemoved}
              ariaLabel={t("declineVoucher")}
            />
          </div>
          <Divider />
        </div>
      ) : undefined}

      {creditOffer && creditOffer.amount.value > 0 ? (
        <div className={styles.travelCreditsSection}>
          <Typography variant="h5">{t("applyCredits")}</Typography>
          <Typography variant="subtitle1">{t("selectCreditsTitle")}</Typography>
          <TextInputField
            placeholder={t("credits")}
            value={credits ?? ""}
            errorHelper={creditsErrorMessage}
            onChange={(value) => setCredits(Number(value))}
            disabled={disabled || disabledCredits}
            required
          />
          <ActionButton
            message={`${t("applyCredits")}: ${formatFiatCurrency({
              value: credits ?? 0,
              currencyCode: creditOffer.amount.currencyCode,
            })}`}
            onClick={onCreditsSelected}
            disabled={disabled || disabledCredits}
          />
          <ActionButton
            message={"Remove credits"}
            onClick={onCreditsRemoved}
            defaultStyle="h4r-secondary"
            disabled={disabled || disabledCredits}
          />
          <Divider />
        </div>
      ) : undefined}
      <div className={styles.statusContainer}>
        <StatusMessage
          type="success"
          icon="coin-piggy"
          message={t("selectCreditsInfo")}
        />
      </div>
    </>
  );
};
