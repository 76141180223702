import { Brand } from "@b2bportal/lloyds-auth-api";

import { LloydsCode } from "../types";

const lloydsCodesArray = Object.keys(LloydsCode);
const lloydsBrandsArray = Object.values(Brand);

export const getBrandFromUrl = (
  hostname: string,
  brandSearchParam?: string
): Brand | undefined => {
  // Check URL query params first
  if (brandSearchParam) {
    if (lloydsBrandsArray.includes(brandSearchParam as Brand)) {
      return brandSearchParam as Brand;
    }
  }

  // Infer from domain
  if (hostname && hostname !== "localhost") {
    const hostnameCode = hostname.split(".")[0];
    if (lloydsCodesArray.includes(hostnameCode)) {
      return LloydsCode[hostnameCode];
    }
  }

  return undefined;
};
