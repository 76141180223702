import { useCallback, useState } from "react";
import { trackEvent } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FloatingBox } from "@hopper-b2b/ui";
import { useOnMount } from "@hopper-b2b/utilities";
import {
  GuestPickerInput,
  HeaderUI,
  HotelLocationAutoComplete,
  MobileHeaderTitle,
} from "@lloyds/ui-connected";
import { BackButton, HotelCalendarPicker } from "@lloyds/ui-connected";
import { ActionButton } from "@lloyds/ui-core";
import { Box, Dialog, Grid } from "@material-ui/core";
import "./MobileHotelSearch.scss";

import { type HotelSearchProps, useHotelSearch } from "../../hooks";
import { useHotelsRecentSearches } from "../../hooks/useLodgingRecentSearch";

export const initialLodgingSearchState = {
  adultGuests: 2,
  childGuests: [],
  rooms: 1,
};

export const MobileHotelSearch = (searchProps: Partial<HotelSearchProps>) => {
  const { t } = useI18nContext();

  useOnMount(() => {
    trackEvent({
      eventName: "hotel_viewed_search",
      properties: undefined,
    });
  });

  const [isCalendarStep, setIsCalendarStep] = useState(false);

  const { recentSearches, filterByDestination } = useHotelsRecentSearches();

  const {
    context: { destination, checkinDate, checkoutDate, guestCount },
    handlers: {
      setCheckoutDate,
      setCheckinDate,
      setGuestCount,
      setDestination,
      handleSearch,
      onRecentSearchClick,
    },
  } = useHotelSearch(searchProps);

  const onSearchClick = useCallback(() => {
    setIsCalendarStep(true);
  }, []);

  const onCalendarBack = useCallback(() => {
    setIsCalendarStep(false);
  }, []);

  return (
    <>
      <Box bgcolor="var(--white)" p={4} borderRadius={16}>
        <Grid container spacing={2} direction="row" alignItems="stretch">
          <Grid item xs={12} sm={3} lg={3}>
            <GuestPickerInput
              guestCounts={guestCount}
              updateGuestCounts={setGuestCount}
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <HotelLocationAutoComplete
              id="destination"
              label=""
              placeholder={t("lodging.search.whereAreYouStaying")}
              recentSearches={recentSearches}
              filterRecentSearches={filterByDestination}
              onRecentSearchClick={onRecentSearchClick}
              onChange={setDestination}
              defaultValue={destination}
              className={"lodging-autocomplete"}
              paperClassName={"lodging-autocomplete-paper"}
              closeIcon={null}
            />
          </Grid>
        </Grid>
      </Box>
      {destination && (
        <FloatingBox>
          <ActionButton
            message={t("lodging.search.selectDestinationButton")}
            onClick={onSearchClick}
          />
        </FloatingBox>
      )}
      <Dialog open={isCalendarStep} fullWidth fullScreen transitionDuration={0}>
        <HeaderUI
          centerItem={<MobileHeaderTitle title={t("calendarTitle")} />}
          leftItem={<BackButton onClick={onCalendarBack} />}
        />
        <HotelCalendarPicker
          guestCount={guestCount}
          destination={destination}
          startDate={checkinDate}
          endDate={checkoutDate}
          setStartDate={setCheckinDate}
          setEndDate={setCheckoutDate}
          onComplete={handleSearch}
        />
      </Dialog>
    </>
  );
};
