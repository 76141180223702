import {
  TextField,
  TextFieldProps as MuiTextFieldProps,
  InputAdornment,
} from "@material-ui/core";
import { ReactNode, useId } from "react";
import clsx from "clsx";
import "./styles.scss";

interface TextFieldProps {
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (
    value: string,
    event?: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  errorHelper?: string;
  width?: string;
  allowInputSizeOverride?: boolean;
  variant?: "filled" | "outlined" | "standard" | "default";
  startContent?: ReactNode;
  endContent?: ReactNode;
}

export type IB2BTextFieldProps = TextFieldProps &
  Omit<MuiTextFieldProps, "onChange" | "onBlur" | "onFocus" | "variant">;

export const B2BTextField = ({ id: inputId, ...rest }: IB2BTextFieldProps) => (
  <B2BTextFieldComponent id={inputId} {...rest} />
);

export const B2BNumberTextField = ({
  id: inputId,
  ...rest
}: IB2BTextFieldProps) => <B2BTextFieldComponent id={inputId} {...rest} />;

const B2BTextFieldComponent = ({
  onChange,
  onFocus,
  onBlur,
  errorHelper,
  inputMode,
  width,
  error,
  className,
  id,
  allowInputSizeOverride,
  variant = "filled",
  startContent,
  endContent,
  ...rest
}: IB2BTextFieldProps) => {
  const inputId = useInputId(id);

  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onBlur) {
      onBlur(event.target.value);
    }
  };

  const handleFocus = (
    event: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (onFocus) {
      onFocus(event.target.value, event);
    }
  };

  return (
    <TextField
      id={inputId}
      error={error || !!errorHelper}
      className={clsx(
        "b2b-text-field",
        { error: error || !!errorHelper },
        className
      )}
      InputProps={{
        startAdornment: (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {startContent != null ? (
              <InputAdornment position="start">{startContent}</InputAdornment>
            ) : null}
          </>
        ),
        endAdornment: (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {endContent != null ? (
              <InputAdornment position="end">{endContent}</InputAdornment>
            ) : null}
          </>
        ),
        disableUnderline: true,
        inputProps: {
          inputMode,
        },
        className: allowInputSizeOverride ? "override-size" : "",
      }}
      InputLabelProps={{
        className: "b2b-text-field-label",
      }}
      onChange={(ev) => handleChange(ev)}
      onFocus={(ev) => handleFocus(ev)}
      onBlur={(ev) => handleBlur(ev)}
      helperText={errorHelper}
      style={{
        width: width,
      }}
      variant={variant === "default" ? undefined : variant}
      {...rest}
    />
  );
};

/**
Custom hook that generates an id in order to always render TextField with an id.
This will prevent an a11y issue "Ensures every form element has a label".
If you need a specific id then you can enforce it by passing `hardcodedId`.
Otherwise, it always generates a unique id using the `useId` hook.
@param hardcodedId - Optional hardcoded id that will come from the `id` prop on `B2BTextField`.
@returns The input id, either the hardcoded id or an id generated by `React.useId`.
*/
function useInputId(hardcodedId?: string) {
  const generatedId = useId();

  return hardcodedId ? hardcodedId : generatedId;
}
