export enum FEATURE_FLAGS {
  CHAT_SUPPORT = "cx-product-chat-support-ui",
  MAINTENANCE = "lloyds-maintenance",
  OUTAGE_BANNER = "lloyds-outage-banner",

  AIR = "lloyds-air",
  LODGING = "lloyds-lodging",
  WALLET = "lloyds-wallet",
  PRICE_PREDICTION = "price-prediction",
  PRICE_DROP_PROTECTION = "price-drop-protection",
  RECENT_FLIGHT_SEARCHES = "recent-flight-searches",
  RECENT_HOTEL_SEARCHES = "recent-hotel-searches",
  NEW_LLOYDS_STYLE = "new_lloyds_style",
}
