import { Link as RouterLink } from "react-router-dom-v5-compat";
import { Typography } from "@material-ui/core";

import styles from "./Link.module.scss";

export const Link = ({ to, text }: { to: string; text: string }) => {
  return (
    <RouterLink to={to} className={styles.link}>
      <Typography color="inherit" variant="inherit">
        {text}
      </Typography>
    </RouterLink>
  );
};
