import type { FiatPrice } from "@b2bportal/purchase-api";
import type { WalletOffer } from "@hopper-b2b/types";

export function discountAmount(
  totalPrice: FiatPrice,
  offer: WalletOffer
): FiatPrice {
  let discount;

  if (offer.percentage) {
    discount =
      offer.maxDiscountAmount &&
      offer.percentage * totalPrice.value > offer.maxDiscountAmount.value
        ? offer.maxDiscountAmount
        : {
            ...totalPrice,
            value: offer.percentage * totalPrice.value,
          };
  } else {
    const absOfferAmount = Math.abs(offer.amount.value);
    discount =
      absOfferAmount > totalPrice.value
        ? totalPrice
        : {
            ...offer.amount,
            value: absOfferAmount,
          };
  }

  return discount;
}
