import { useMemo } from "react";
import { useLocation } from "react-router-dom-v5-compat";
import { CheckoutQueryParams, ParentState } from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import { BackButton, HeaderUI, MobileHeaderTitle } from "@lloyds/ui-connected";

export const CheckoutHeader = () => {
  const { t } = useI18nContext();

  const { search } = useLocation();
  const checkoutState = new URLSearchParams(search).get(
    CheckoutQueryParams.checkoutState
  );

  const title = useMemo(() => {
    switch (checkoutState) {
      case ParentState.passengerInformation:
      case ParentState.passport:
        return t("travelerInformation");
      case ParentState.cardPayment:
        return t("paymentInformation");
      case ParentState.review:
        return t("reviewDetails");
      case ParentState.seatSelection:
        return t("seatSelection");
      case ParentState.bookingConfirmation:
        return null;
      default:
        return t(checkoutState);
    }
  }, [checkoutState, t]);

  return (
    <HeaderUI
      leftItem={
        checkoutState !== ParentState.bookingConfirmation && <BackButton />
      }
      centerItem={<MobileHeaderTitle title={title} />}
    />
  );
};
