import {
  Payment,
  type PaymentId,
  type PaymentOpaqueValue,
} from "@b2bportal/purchase-api";
import { ParentState } from "@checkout/types";
import { assign } from "xstate";

import type {
  SetWalletCreditsEvent,
  RemoveWalletCreditsEvent,
} from "../events";
import type { CommonMachineContext } from "../types";

export const walletCreditActions = {
  setWalletCredits: assign(
    (ctx: CommonMachineContext, event: SetWalletCreditsEvent) => {
      const credits = event.credits;
      if (credits) {
        ctx[ParentState.credits].walletCredits = credits;
        const walletCreditPayment: PaymentOpaqueValue = {
          type: Payment.Wallet,
          value: {
            offerId: credits.id,
            totalDiscountAmount: credits.amount,
            paymentType: "Credit",
          },
        };

        ctx[ParentState.cartUpdate].addPayments = [
          ...(ctx[ParentState.cartUpdate].addPayments ?? []),
          walletCreditPayment,
        ];
      }

      return ctx;
    }
  ),
  removeWalletCredits: assign(
    (ctx: CommonMachineContext, _event: RemoveWalletCreditsEvent) => {
      const offerAsPayment: PaymentId = {
        id: ctx[ParentState.credits].walletCredits.id,
        payment: Payment.Wallet,
      };
      ctx[ParentState.credits].walletCredits = undefined;

      ctx[ParentState.cartUpdate].removePayments = [
        ...(ctx[ParentState.cartUpdate].removePayments ?? []),
        ...[offerAsPayment],
      ];
      return ctx;
    }
  ),
};
