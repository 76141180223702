import { FlightSearch } from "./FlightSearch";
import { HotelSearch } from "./HotelSearch";
import { TabbedSearch } from "./TabbedSearch";

export const enum SearchType {
  AIR,
  LODGING,
}

export const Search = ({ type }: { type?: SearchType }) => {
  return type === SearchType.AIR ? (
    <FlightSearch />
  ) : type === SearchType.LODGING ? (
    <HotelSearch />
  ) : (
    <TabbedSearch />
  );
};
