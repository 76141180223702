import { useI18nContext } from "@hopper-b2b/i18n";

import { SelectField } from "../SelectField";

export type AssistancePickerProps = {
  assistance: string[];
  assistanceOptions: {
    label: string | JSX.Element;
    value: string;
  }[];
  selected: string;
  optionMenuItems: JSX.Element;
  setAssistance: (assistanceOptions: string[]) => void;
};

export const AssistancePicker = ({
  assistance,
  assistanceOptions,
  setAssistance,
}: AssistancePickerProps) => {
  const { t } = useI18nContext();

  return (
    <SelectField
      options={assistanceOptions}
      setOption={setAssistance}
      selected={assistance}
      allowMultiSelection={true}
      label={t("flight.checkout.travelerInfoForm.requireAssistance")}
    />
  );
};
