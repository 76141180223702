import { type ReactNode, useMemo } from "react";
import { useModuleBEM } from "@b2bportal/core-themes";

import clsx from "clsx";

import { TextButton } from "../TextButton";

import { ReactComponent as Coins } from "./CoinIcon.svg";
import { ReactComponent as CoinPiggy } from "./CoinPiggy.svg";
import { ReactComponent as ErrorIcon } from "./ErrorIcon.svg";
import { ReactComponent as InfoIcon } from "./InfoIcon.svg";
import { ReactComponent as MoneyBack } from "./MoneyBack.svg";
import { ReactComponent as PriceDropIcon } from "./ProtectionIcon.svg";
import { ReactComponent as RefundIcon } from "./RefundIcon.svg";
import { ReactComponent as RewardIcon } from "./RewardIcon.svg";
import styles from "./StatusMessage.module.scss";
import { ReactComponent as SuccessIcon } from "./SuccessIcon.svg";
import { ReactComponent as WalletIcon } from "./WalletIcon.svg";
import { ReactComponent as WarningIcon } from "./WarningIcon.svg";

export const StatusMessage = ({
  type,
  icon,
  spacing = "normal",
  title,
  message,
  actionMessage,
  classname,
  onActionMessageClick,
}: {
  spacing?: "compact" | "normal";
  icon?:
    | "warning"
    | "critical"
    | "info"
    | "success"
    | "reward"
    | "refund"
    | "price-drop"
    | "money-back"
    | "coin-piggy"
    | "wallet"
    | "coins";
  type: "warning" | "critical" | "info" | "success";
  title?: ReactNode;
  message: ReactNode;
  classname?: string;
  actionMessage?: string;
  onActionMessageClick?: () => void;
}) => {
  const COMPONENT_KEY = "StatusMessage";
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const Icon = useMemo(() => {
    if (!icon) {
      switch (type) {
        case "info":
          return InfoIcon;
        case "warning":
          return WarningIcon;
        case "critical":
          return ErrorIcon;
        case "success":
          return SuccessIcon;
        default:
          return InfoIcon;
      }
    }

    switch (icon) {
      case "info":
        return InfoIcon;
      case "warning":
        return WarningIcon;
      case "critical":
        return ErrorIcon;
      case "success":
        return SuccessIcon;
      case "reward":
        return RewardIcon;
      case "wallet":
        return WalletIcon;
      case "refund":
        return RefundIcon;
      case "price-drop":
        return PriceDropIcon;
      case "coin-piggy":
        return CoinPiggy;
      case "money-back":
        return MoneyBack;
      case "coins":
        return Coins;
      default:
        return InfoIcon;
    }
  }, [icon, type]);

  return (
    <div className={clsx(block, classname, cn("container", [type, spacing]))}>
      <div className={cn("icon")}>
        <Icon />
      </div>
      <div className={cn("text-container")}>
        {title && <div className={cn("title")}>{title}</div>}
        <div className={cn("message", [type])}>{message}</div>
        {actionMessage && (
          <div className={cn("action")}>
            <TextButton
              onClick={onActionMessageClick}
              size="medium"
              message={actionMessage}
            />
          </div>
        )}
      </div>
    </div>
  );
};
