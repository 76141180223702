import { useCallback } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Box } from "@material-ui/core";

import { SelectField } from "../../../SelectField";

export const ChildrenAgePicker = ({
  childCount,
  childrenAges,
  onChildAgesChange,
}: {
  childCount: number;
  childrenAges: number[];
  onChildAgesChange: (childrenAges: number[]) => void;
}) => {
  const { t } = useI18nContext();

  const captureChildAge = useCallback(
    (childIndex: number, age: number) => {
      const newChildrenAges = [...childrenAges];
      newChildrenAges[childIndex] = age;
      onChildAgesChange(newChildrenAges);
    },
    [childrenAges, onChildAgesChange]
  );

  if (childCount <= 0) return null;

  return (
    <Box display="flex" flexDirection="column">
      {Array.from({ length: childCount }).map((_, childIndex) => (
        <Box
          paddingX="24px"
          paddingY="16px"
          borderBottom={"var(--divider-border)"}
          gridGap={8}
          display="flex"
          flexDirection="column"
          key={childIndex}
        >
          <SelectField
            label={t("guestCount.childrenAgePlaceholder", {
              index: childIndex + 1,
            })}
            required
            selected={String(childrenAges[childIndex]) ?? undefined}
            placeholder={"Select child age"}
            options={[
              { value: String(0), label: "Under 1" },
              ...[...Array(17).keys()].map((key) => ({
                value: String(key + 1),
                label: String(key + 1),
              })),
            ]}
            setOption={(age) => captureChildAge(childIndex, Number(age))}
          />
        </Box>
      ))}
    </Box>
  );
};
