import {
  type PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from "react";
import { Redirect } from "react-router";
import { useNavigate } from "react-router-dom-v5-compat";
import type { SessionData } from "@b2bportal/lloyds-auth-api";
import { setDatadogUser } from "@hopper-b2b/datadog";
import { B2B_PORTAL_AUTH_REDIRECT_TO } from "@hopper-b2b/types";
import {
  PATH_AUTH,
  PATH_AUTH_INVALID_SESSION,
  PATH_HOME,
  useIsSessionAuthenticated,
} from "@hopper-b2b/utilities";

import { fetchUserInfo } from "../../api";
import { UserSessionContext } from "../../context";

export const doFetchUserInfo = async (onSuccess, onFailure) => {
  try {
    const userInfo = await fetchUserInfo();
    onSuccess(userInfo);
  } catch (err) {
    onFailure(err);
  }
};

export const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const { userSessionInfo, updateSessionInfo } = useContext(UserSessionContext);
  const isLoggedIn = useIsSessionAuthenticated();

  const shouldFetchUserInfo = useMemo(
    () =>
      !userSessionInfo ||
      !userSessionInfo.userInfo ||
      !userSessionInfo.accessToken,
    [userSessionInfo]
  );

  const onFetchUserInfoSuccess = useCallback(
    (response: SessionData) => {
      updateSessionInfo(response);
    },
    [updateSessionInfo]
  );

  const onFetchUserInfoFailure = useCallback(() => {
    const redirectUrl = `${window.location.pathname}${window.location.search}`;
    if (!redirectUrl.includes(PATH_AUTH)) {
      sessionStorage.setItem(B2B_PORTAL_AUTH_REDIRECT_TO, redirectUrl);
    }
    // TODO: before deploying to prod, this should go back to nubank app
    navigate(PATH_AUTH_INVALID_SESSION);
  }, [navigate]);

  useEffect(() => {
    if (shouldFetchUserInfo) {
      doFetchUserInfo(onFetchUserInfoSuccess, onFetchUserInfoFailure);
    }
  }, [onFetchUserInfoFailure, onFetchUserInfoSuccess, shouldFetchUserInfo]);

  useEffect(() => {
    if (isLoggedIn && userSessionInfo?.customerId) {
      setDatadogUser({
        id: userSessionInfo.customerId,
      });
    }
  }, [isLoggedIn, userSessionInfo?.customerId]);

  return shouldFetchUserInfo || isLoggedIn ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <Redirect to={`${PATH_HOME}${PATH_AUTH_INVALID_SESSION}`} />
  );
};
