import type { ReactNode } from "react";
import { LbgModal } from "@lloyds/ui-connected";

import styles from "./AdyenSlot.module.scss";

export const AdyenSlot = ({
  children,
  showChallenge,
}: {
  children: ReactNode;
  showChallenge: boolean;
}) => {
  return (
    <LbgModal
      fullScreen
      open={true}
      disableContentPadding
      className={!showChallenge && styles.hidden}
    >
      {children}
    </LbgModal>
  );
};
