import {
  CORE_FINTECH_UI_NAME,
  CoreFintechUiComponents,
  CORE_DISRUPTION_NAME,
  CoreDisruptionComponent,
  CORE_FLIGHTS_CFAR_NAME,
  CoreCfarComponent,
} from "@b2bportal/core-types";

import {
  fintechProductBulletStyles,
  disruptionOfferSectionStyles,
  cfarOfferSectionStyles,
  howItWorksDialogStyles,
  fintechProductStyles,
} from "./styles";

export const styleOverrides = {
  [CORE_FINTECH_UI_NAME]: {
    [CoreFintechUiComponents.FintechProductBullet]: fintechProductBulletStyles,
    [CoreFintechUiComponents.FintechProduct]: fintechProductStyles,
  },
  [CORE_DISRUPTION_NAME]: {
    [CoreDisruptionComponent.DisruptionOfferSection]:
      disruptionOfferSectionStyles,
    [CoreDisruptionComponent.HowItWorksDialog]: howItWorksDialogStyles,
  },
  [CORE_FLIGHTS_CFAR_NAME]: {
    [CoreCfarComponent.CfarOfferSection]: cfarOfferSectionStyles,
  },
};
