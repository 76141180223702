import { useCallback, useState } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  ActionButton,
  TextButton,
  type TextButtonProps,
} from "@lloyds/ui-core";

import {
  ConfirmationDialog,
  type ConfirmationDialogProps,
} from "../ConfirmationDialog";

export type DeleteButtonProps = {
  id: string;
  variant?: "text" | "outlined";
} & TextButtonProps &
  Pick<ConfirmationDialogProps, "title" | "description" | "acceptButtonProps">;

export const DeleteButton = ({
  id,
  message,
  onClick,
  title,
  description,
  acceptButtonProps,
  variant = "outlined",
}: DeleteButtonProps) => {
  const { t } = useI18nContext();

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const onConfirmationOpen = useCallback(() => {
    setConfirmationOpen(true);
  }, []);

  const onConfirmationClose = useCallback(() => {
    setConfirmationOpen(false);
  }, []);

  const onConfirmationConfirm = useCallback(
    (e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick?.(e);
      onConfirmationClose();
    },
    [onClick, onConfirmationClose]
  );

  return (
    <>
      {variant === "text" ? (
        <TextButton message={message} onClick={onConfirmationOpen} />
      ) : (
        <ActionButton
          defaultStyle="h4r-secondary"
          onClick={onConfirmationOpen}
          message={message}
        />
      )}
      <ConfirmationDialog
        id={`${id}-remove`}
        title={title}
        description={description}
        open={confirmationOpen}
        onClose={onConfirmationClose}
        onConfirm={onConfirmationConfirm}
        acceptButtonProps={{
          onClick: onConfirmationConfirm,
          ...acceptButtonProps,
        }}
        cancelButtonProps={{
          onClick: onConfirmationClose,
          message: t("cancel"),
        }}
      />
    </>
  );
};
