import React, { type ReactNode, useCallback, useEffect, useState } from "react";
import type { SearchParameters } from "@b2bportal/air-shopping-api";
import { useI18nContext } from "@hopper-b2b/i18n";
import type { IApiConfig } from "@hopper-b2b/types";
import { apiConfig, useDebounce, useDeviceTypes } from "@hopper-b2b/utilities";
import { Box, InputAdornment, Typography } from "@material-ui/core";
import {
  type AutocompleteRenderGroupParams,
  type AutocompleteRenderInputParams,
  type AutocompleteRenderOptionState,
  Autocomplete as MuiAutocomplete,
} from "@material-ui/lab";
import clsx from "clsx";

import { TextInputField } from "../TextInputField";

import { ReactComponent as Clear } from "./clear.svg";
import "./Autocomplete.scss";

export type AutocompleteProps<T> = {
  id: string;
  defaultValue?: T;
  label: string;
  disabled?: boolean;
  icon?: React.ReactElement;
  className?: string;
  paperClassName?: string;
  inputClassName?: string;
  inputPropsClassName?: string;
  placeholder?: string;
  closeIcon?: React.ReactNode;
  additionalSearchControl?: SearchParameters;
  staticOptions?: T[];
  filterStaticOptions?: (staticOptions: T[], search: string) => T[];
  onChange: (value?: T) => void;
  onOpen?: () => void;
  groupBy?: (option: T) => string;
  renderGroup?: (params: AutocompleteRenderGroupParams) => ReactNode;
  getOptionLabel?: (option: T) => string;
  getOptionSublabel?: (option: T) => string;
  getOptionLabelIcon?: (option: T) => ReactNode;
  sortOptions?: (options?: T[]) => T[];
  renderOption?: (
    option: T,
    state: AutocompleteRenderOptionState
  ) => React.ReactNode;
  fetch: (
    apiConfig: IApiConfig | undefined,
    search: string,
    onSuccess: (newOptions: T[]) => void
  ) => Promise<void>;
};

export const Autocomplete = React.forwardRef(
  <T,>(
    {
      id,
      defaultValue,
      label,
      disabled,
      icon,
      className,
      inputClassName,
      inputPropsClassName,
      paperClassName,
      placeholder,
      closeIcon,
      staticOptions,
      filterStaticOptions,
      onOpen,
      fetch,
      sortOptions,
      onChange,
      getOptionLabel,
      getOptionSublabel,
      getOptionLabelIcon,
      groupBy,
      renderGroup,
      renderOption,
    }: AutocompleteProps<T>,
    forwardedRef
  ) => {
    const { t } = useI18nContext();
    const { matchesMobile } = useDeviceTypes();

    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [shrink, setShrink] = useState<boolean>(false);

    const [value, setValue] = useState<T>(defaultValue ?? null);
    const [search, setSearch] = useState("");
    const [options, setOptions] = useState<T[]>([]);

    const debouncedSearch = useDebounce(search, 300);

    useEffect(() => {
      setValue(defaultValue ?? null);
    }, [defaultValue]);

    useEffect(() => {
      if (!open) return;

      setLoading(true);
      const filteredStaticOptions = filterStaticOptions
        ? filterStaticOptions(staticOptions, debouncedSearch)
        : staticOptions ?? [];
      fetch(apiConfig, debouncedSearch, (newOptions: T[]) => {
        setOptions(
          sortOptions
            ? sortOptions([...filteredStaticOptions, ...newOptions])
            : [...filteredStaticOptions, ...newOptions]
        );
        setLoading(false);
      });
    }, [
      fetch,
      open,
      staticOptions,
      filterStaticOptions,
      debouncedSearch,
      sortOptions,
    ]);

    const handleOnInputChange = useCallback((_event, newInputValue) => {
      setSearch(newInputValue);
    }, []);

    const handleOnChangeValue = useCallback(
      (_event, value: T) => {
        setValue(value);
        onChange(value);
      },
      [onChange]
    );

    const handleOnOpen = useCallback(() => {
      onOpen && onOpen();
      setSearch("");
      setOpen(true);
    }, [onOpen]);

    const handleOnClose = useCallback(() => {
      setOpen(false);
    }, []);

    const handleOnFocus = useCallback(() => {
      setTimeout(() => {
        if (forwardedRef?.current) {
          forwardedRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      }, 100);
      setShrink(true);
    }, [setShrink, forwardedRef]);

    const handleOnBlur = useCallback(() => {
      setShrink(false);
    }, []);

    const handleOnClear = useCallback(() => {
      setSearch("");
    }, []);

    const defaultRenderGroup = useCallback(
      ({ key, group, children }: AutocompleteRenderGroupParams) =>
        matchesMobile ? (
          <Box key={key} className="autocomplete-group">
            <Box className="autocomplete-group-title-container">
              <Typography
                variant="inherit"
                className="autocomplete-group-title"
              >
                {t(group)}
              </Typography>
            </Box>
            <Box className="autocomplete-group-options">{children}</Box>
          </Box>
        ) : (
          <div key={key}>{children}</div>
        ),
      [matchesMobile, t]
    );
    const defaultRenderOption = useCallback(
      (value?: T) => (
        <Box
          display="flex"
          flexDirection="row"
          alignItems={"center"}
          width={"100%"}
        >
          {getOptionLabelIcon && (
            <Box className={"autocomplete-options-icon"}>
              {getOptionLabelIcon(value)}
            </Box>
          )}
          <Box
            display={"flex"}
            flexDirection={"column"}
            className="autocomplete-options-label"
          >
            {getOptionLabel && getOptionLabel(value) && (
              <Typography
                variant="subtitle1"
                className="autocomplete-option-label"
                noWrap
                style={{ fontWeight: 500 }}
              >
                {getOptionLabel(value)}
              </Typography>
            )}
            {getOptionSublabel && getOptionSublabel(value) && (
              <Typography
                className="autocomplete-option-sublabel"
                variant="subtitle1"
                noWrap
              >
                {getOptionSublabel(value)}
              </Typography>
            )}
          </Box>
        </Box>
      ),
      [getOptionLabel, getOptionSublabel, getOptionLabelIcon]
    );

    const renderInput = useCallback(
      (params: AutocompleteRenderInputParams) => (
        <TextInputField
          {...params}
          id={id}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            startAdornment: (
              <>
                {icon && (
                  <InputAdornment position="start">{icon}</InputAdornment>
                )}
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: search && open && (
              <InputAdornment
                position="end"
                className={"autocomplete-endAdornment"}
                onClick={handleOnClear}
              >
                <Clear />
              </InputAdornment>
            ),
          }}
          InputLabelProps={{
            shrink: shrink || !!value,
            focused: false,
            className: "autocomplete-label",
          }}
          label={label}
          aria-label={label.length ? label : t("autocomplete")}
          placeholder={placeholder}
          className={clsx("autocomplete-input", inputClassName)}
          inputRef={forwardedRef}
          inputProps={{
            ...params.inputProps,
            className: clsx(params.inputProps?.className, inputPropsClassName),
          }}
        />
      ),
      [
        id,
        icon,
        handleOnClear,
        open,
        search,
        shrink,
        value,
        label,
        t,
        placeholder,
        inputClassName,
        forwardedRef,
        inputPropsClassName,
      ]
    );

    return (
      <MuiAutocomplete
        id={id}
        open={open}
        disabled={disabled}
        onOpen={handleOnOpen}
        onClose={handleOnClose}
        value={value}
        inputValue={search}
        defaultValue={defaultValue}
        options={options}
        loading={loading}
        loadingText={t("loading.autocomplete")}
        noOptionsText={t("noOptions")}
        classes={{ paper: clsx("lloyds-autocomplete-paper", paperClassName) }}
        onChange={handleOnChangeValue}
        onInputChange={handleOnInputChange}
        getOptionLabel={getOptionLabel}
        groupBy={groupBy}
        disableClearable={loading}
        onFocus={handleOnFocus}
        blurOnSelect
        onBlur={handleOnBlur}
        renderInput={renderInput}
        renderGroup={renderGroup ? renderGroup : defaultRenderGroup}
        renderOption={renderOption ? renderOption : defaultRenderOption}
        closeIcon={closeIcon}
        className={clsx(className, open ? "open" : undefined)}
        // Disable UI filtering
        filterOptions={(options: T[]) => options}
        aria-labelledby={`${id}-label`}
      />
    );
  }
);
