import type { ReactNode } from "react";
import { useExperiment } from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import { Slot } from "@hopper-b2b/ui";
import { FEATURE_FLAGS } from "@lloyds/theming";
import { AppBar, Box, Container, Toolbar, Typography } from "@material-ui/core";

import styles from "./HeaderUI.module.scss";

export interface HeaderUIProps {
  rightItem?: ReactNode;
  leftItem?: ReactNode;
  centerItem?: ReactNode;
  banner?: ReactNode;
}

export const HeaderUI = ({
  centerItem,
  leftItem,
  rightItem,
  banner,
}: HeaderUIProps) => {
  const { t } = useI18nContext();

  const outageBannerEnabled = useExperiment(FEATURE_FLAGS.OUTAGE_BANNER);

  return (
    <AppBar>
      <Container disableGutters maxWidth="xl">
        <Toolbar>
          <Box
            py={{ md: 3 }}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
          >
            <Box>{leftItem}</Box>
            <Box className={styles.centerItem}>{centerItem}</Box>
            <Box>{rightItem}</Box>
          </Box>
        </Toolbar>
      </Container>
      {banner}
      <Slot
        id={"default-outage-banner"}
        enabled={outageBannerEnabled}
        bannerTitle={t("outageBannerTitle")}
        bannerSubtitle={t("outageBannerSubtitle")}
      />
    </AppBar>
  );
};

export const MobileHeaderTitle = ({
  title,
  subtitle,
}: {
  title: ReactNode;
  subtitle?: ReactNode;
}) => {
  return (
    <Box textAlign="center">
      <Typography noWrap variant="inherit" className={styles.title}>
        {title}
      </Typography>
      <Typography variant="caption">{subtitle}</Typography>
    </Box>
  );
};
