import { useCallback } from "react";
import { TIME_RANGE_MAX, TIME_RANGE_STEP } from "@b2bportal/core-flights";
import { useFlightStyles, useModuleBEM } from "@b2bportal/core-themes";
import { CoreFlightsComponent } from "@b2bportal/core-types";
import { destinationToCity } from "@b2bportal/core-utilities";
import {
  type FilterTimeComponentProps,
  useFilterTime,
} from "@components/flights";
import { Slider } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";

import clsx from "clsx";
import dayjs from "dayjs";

import defaultStyles from "./FilterTime.module.scss";

const getTimeLabel = (value: number) => {
  const totalTime = dayjs().hour(0).minute(value);
  return totalTime.format("HH:mm");
};

export const FilterTime = ({
  origin,
  destination,
  departureTimeRange,
  arrivalTimeRange,
  onSliceTimeRangeChange,
}: FilterTimeComponentProps) => {
  const COMPONENT_KEY = CoreFlightsComponent.FilterTime;
  const styles = useFlightStyles(COMPONENT_KEY, defaultStyles);
  const [block, cn] = useModuleBEM(styles, COMPONENT_KEY);

  const {
    departureTimeRangeInternal,
    arrivalTimeRangeInternal,
    handleTimeRangeChange,
  } = useFilterTime({
    initialDepartureTimeRange: departureTimeRange,
    initialArrivalTimeRange: arrivalTimeRange,
    onTimeRangeChange: onSliceTimeRangeChange,
  });

  const { t } = useI18nContext();
  const departureRangeText = useCallback(
    (timeRange: { min: number; max: number }) => {
      const { min, max } = timeRange;
      if (min === 0 && max === TIME_RANGE_MAX) {
        return t("flight.searchFilter.anytime");
      }
      return `${getTimeLabel(min)} - ${getTimeLabel(max)}`;
    },
    [t]
  );

  return (
    <div className={clsx(block)}>
      <div className={cn("slice-range")}>
        <div className={cn("slider")}>
          <Slider
            min={0}
            max={TIME_RANGE_MAX}
            ariaLabel=""
            value={[
              departureTimeRangeInternal?.min ?? 0,
              departureTimeRangeInternal?.max ?? TIME_RANGE_MAX,
            ]}
            step={TIME_RANGE_STEP}
            onValueChange={(val) =>
              handleTimeRangeChange(
                { min: val[0], max: val[1] },
                arrivalTimeRange
              )
            }
            minStepsBetweenThumbs={1}
          />
        </div>
        <div className={cn("range-subtitle")}>
          {t("flight.searchFilter.departFrom", {
            city: destinationToCity(origin),
          })}
        </div>
        <div className={cn("time-range-text")}>
          {departureRangeText(departureTimeRangeInternal)}
        </div>
      </div>
      <div className={cn("slice-range")}>
        <div className={cn("slider")}>
          <Slider
            min={0}
            max={TIME_RANGE_MAX}
            ariaLabel=""
            value={[
              arrivalTimeRangeInternal?.min ?? 0,
              arrivalTimeRangeInternal?.max ?? TIME_RANGE_MAX,
            ]}
            step={TIME_RANGE_STEP}
            onValueChange={(val) =>
              handleTimeRangeChange(departureTimeRange, {
                min: val[0],
                max: val[1],
              })
            }
            minStepsBetweenThumbs={1}
          />
        </div>
        <div className={cn("range-subtitle")}>
          {t("flight.searchFilter.arriveIn", {
            city: destinationToCity(destination),
          })}
        </div>
        <div className={cn("time-range-text")}>
          {departureRangeText(arrivalTimeRangeInternal)}
        </div>
      </div>
    </div>
  );
};
