import { useLocation } from "react-router-dom-v5-compat";
import {
  FLIGHTS_PATH,
  FLIGHT_BOOK_PATH,
  HOTELS_BOOK_PATH,
  HOTELS_PATH,
  TRIPS_PATH,
} from "@b2bportal/core-utilities";

import { CheckoutHeader } from "./CheckoutHeader";
import { FlightsHeader } from "./FlightHeader";
import { LodgingHeader } from "./LodgingHeader/LodgingHeader";
import { MyTripHeader } from "./MyTripsHeader";

export const MobileHeader = () => {
  const { pathname } = useLocation();

  switch (true) {
    case pathname.includes(FLIGHT_BOOK_PATH):
    case pathname.includes(HOTELS_BOOK_PATH):
      return <CheckoutHeader />;
    case pathname.includes(FLIGHTS_PATH):
      return <FlightsHeader />;
    case pathname.includes(TRIPS_PATH):
      return <MyTripHeader />;
    case pathname.includes(HOTELS_PATH):
      return <LodgingHeader />;
    default:
      return null;
  }
};
