// eslint-disable-next-line @nx/enforce-module-boundaries
import { memo, useCallback, useMemo, useState } from "react";
import type { IB2BTextFieldProps } from "@hopper-b2b/ui";
import { Box, TextField as MuiTextField } from "@material-ui/core";

import { ReactComponent as ClearIcon } from "./clear.svg";

export type NumberInputFieldProps = Omit<TextInputFieldProps, "type">;

export const NumberInputField = memo(
  ({ inputProps, ...rest }: NumberInputFieldProps) => {
    const mergedInputProps = {
      ...inputProps,
      pattern: "[0-9]*",
    };

    return (
      <TextInputField type="tel" inputProps={mergedInputProps} {...rest} />
    );
  }
);

export type TextInputFieldProps = IB2BTextFieldProps & {
  clearable?: boolean;
};

export const TextInputField = memo(
  ({
    clearable = true,
    variant = "filled",
    value,
    disabled,
    helperText: helperTextProps,
    error: _error, // Ignore error, we'll calculate it internally
    errorHelper,
    onChange,
    onBlur,
    onFocus,
    inputProps,
    "aria-label": ariaLabel,
    InputProps = {},
    ...rest
  }: TextInputFieldProps) => {
    const [touched, setTouched] = useState(false);
    const [focus, setFocus] = useState(false);

    const helperText = helperTextProps ?? errorHelper;

    const showError = useMemo(
      () => touched && !focus && !!helperText,
      [focus, helperText, touched]
    );

    const showClear = useMemo(
      () => clearable && !disabled && value && value !== "",
      [clearable, disabled, value]
    );

    const handleChange = useCallback(
      (e) => {
        onChange?.(e?.target?.value);
      },
      [onChange]
    );

    const handleBlur = useCallback(
      (e) => {
        onBlur?.(e?.target?.value);
        setFocus(false);
      },
      [onBlur]
    );

    const handleFocus = useCallback(
      (e) => {
        onFocus?.(e?.target?.value);
        setTouched(true);
        setFocus(true);
      },
      [onFocus]
    );

    const handleClear = useCallback(() => {
      handleChange({ target: { value: "" } });

      setTouched(true);
    }, [handleChange]);

    return (
      <MuiTextField
        variant={variant === "default" ? undefined : variant}
        value={value}
        disabled={disabled}
        error={showError}
        helperText={showError && helperText}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <>
              {showClear && (
                <Box
                  display="flex"
                  flexDirection="row-reverse"
                  alignItems="center"
                  minWidth="18px"
                  height="18px"
                  onClick={handleClear}
                >
                  <ClearIcon height={13} width={13} />
                </Box>
              )}
              {InputProps?.endAdornment}
            </>
          ),
        }}
        inputProps={{
          ...inputProps,
          "aria-label": ariaLabel,
        }}
        {...rest}
      />
    );
  }
);
