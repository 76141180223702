import { Search } from "@lloyds/search";
import { WalletNotification } from "@lloyds/wallet";
import { Box, Container, Hidden } from "@material-ui/core";

import styles from "./HomeRoute.module.scss";

export const HomeRouteComponent = () => {
  return (
    <Box
      style={{
        position: "relative",
        height: "100vh",
        width: "100%",
        backgroundColor: "var(--background-color)",
      }}
    >
      <Box
        style={{
          position: "relative",
          zIndex: 2,
        }}
      >
        <Hidden smDown>
          <Box className={styles.desktopContainer}>
            <Container maxWidth="xl" disableGutters>
              <Box className={styles.desktopSearch}>
                <Search />
              </Box>
            </Container>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box px="20px" position="absolute" top={16}>
            <WalletNotification />
          </Box>
          <Box className={styles.mobileContainer}>
            <Container disableGutters maxWidth="xl">
              <Search />
            </Container>
          </Box>
        </Hidden>
      </Box>
    </Box>
  );
};
