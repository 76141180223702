import { defaultThemeConfigWithBasicColors } from "@hopper-b2b/themes";
import {
  alpha,
  createTheme,
  type ThemeOptions,
} from "@material-ui/core/styles";
import { deepmerge } from "@material-ui/utils";
import LloydsBold from "./lloydsBankJackBold.woff2";
import LloydsLight from "./lloydsBankJackLight.woff2";
import LloydsMedium from "./lloydsBankJackMedium.woff2";
import LloydsRegular from "./lloydsBankJackRegular.woff2";

const BrandColors = {
  /////////////////////
  //  Lloyds Colors  //
  /////////////////////

  // Primary
  "lloyds-primary-1": "#006A4D",
  "lloyds-primary-2": "#024731",
  "lloyds-primary-3": "#77BA00",
  "lloyds-primary-4": "#FFFFFF",
  "lloyds-primary-background": "#2C684E",
  // Secondary
  "lloyds-secondary-1": "#2178B0",
  "lloyds-secondary-2": "#481468",
  "lloyds-secondary-3": "#025C69",
  // Greys
  "lloyds-tertiary": "#F1F1F1",
  "lloyds-grey-100": "var(--lloyds-tertiary)",
  "lloyds-grey-200": "#ADADAD",
  "lloyds-grey-300": "#767676",
  "lloyds-grey-400": "#505050",
  "lloyds-grey-500": "#323233",
  // Colors
  "lloyds-red-100": "#FEF6F8",
  "lloyds-red-500": "#D92049",
  "lloyds-green-100": "#EEF6E9",
  "lloyds-green-500": "#649C00",
  "lloyds-orange-100": "#FFF7E8",
  "lloyds-orange-500": "#DC5309",
  "lloyds-blue-100": "#E6EEF4",
  "lloyds-blue-500": "#0D5595",
  // Variables
  "lloyds-success": "var(--lloyds-green-500)",
  "lloyds-critical": "var(--lloyds-red-500)",
  "lloyds-warning": "var(--lloyds-orange-500)",
  "lloyds-information": "var(--lloyds-blue-500)",
  // Text
  "lloyds-text-default-1": "var(--lloyds-grey-400)",
  "lloyds-text-default-2": "var(--lloyds-grey-500)",
  "lloyds-text-subdued": "var(--lloyds-grey-300)",
  "lloyds-text-brand": "var(--lloyds-primary-2)",
  "lloyds-text-critical": "var(--lloyds-red-500)",
  // End of Lloyds Colors //

  "grey-10": "#EFEFEF",
  "grey-50": "#6A6A6A",
  "grey-3": "#E8E8E8",
  "grey-1": "#333333",
  "grey-disabled": "#EAEAEA",
};

const CoreColors = {
  primary: "#006A4D",
  "primary-2": "#024731",
  "primary-subdued": "var(--lloyds-green-100)",

  border: "#EDEDED",

  /** Common */
  "text-primary": "#323233",
  "text-secondary": "#333333",
  "text-tertiary": "#565656",
  "link-primary": "var(--lloyds-text-brand)",

  /** Button */
  "button-primary": "var(--white)",
  "button-primary-background": "var(--primary)",
  "button-primary-hover-background": "var(--lloyds-primary-2)",
  "button-primary-hover": "var(--white)",
  "button-primary-disabled": "var(--lloyds-grey-200)",
  "button-primary-disabled-background": "var(--grey-disabled)",
  "button-secondary": "var(--primary)",
  "button-secondary-background": "var(--white)",
  "button-secondary-border": "var(--primary)",

  /** ========= UI ======== */
  "background-color": "var(--white)",

  "slider-background-color": "var(--grey-10)",
  "slider-range-background-color": "var(--primary)",
  "slider-thumb-background-color": "var(--primary)",
  "slider-thumb-value-background-color": "var(--primary)",
  "slider-thumb-value-color": "var(--white)",

  "page-header-background-color": "var(--primary)",
  "page-header-text-color": "var(--white)",
  "page-footer-background-color": "var(--primary)",
  "page-footer-text-color": "var(--white)",

  "popup-background-color": "var(--white)",

  "homepage-title-primary": "var(--primary)",

  "checkbox-border-color": "var(--grey-50)",
  "checkbox-checked-border-color": "var(--primary)",
  "checkbox-checked-background-color": "var(--primary)",
  "checkbox-checked-icon-color": "var(--white)",

  "input-background-color": "var(--white)",
  "input-border-color": "var(--lloyds-red-100)",
  "input-error-color": "var(--lloyds-red-500)",
  "input-error-border-color": "var(--lloyds-red-500)",

  /** ========= Flights ======== */
  "flight-list-search-background": "var(--white)",
  "algomerch-tag-background": "rgba(0, 163, 207, 0.10)",
  "algomerch-tag-text": "#013D58",
  "danger-text": "#CC2427",
  "fare-list-background": "var(--white)",

  "segment-icon-color": "var(--lloyds-grey-200)",
  "segment-layover-color": "var(--lloyds-blue-100)",
  "flight-fare-notice": "rgba(1, 61, 88, 1.0)",
  "flight-fare-option-border": "var(--lloyds-grey-200)",
  "flight-fare-option-border-selected": "var(--primary)",
  "flight-fare-option-selected": "var(--primary)",

  "flight-fare-details-more-button-background": "var(--grey-10)",

  "flight-loading-background-color": "var(--primary)",
  "flight-loading-text-color": "var(--white)",

  "flight-card-summary-icon-color": "rgb(224, 224, 224)",
  "flight-card-summary-border-color": "var(--lloyds-grey-200)",
  "flight-card-summary-divider": "var(--lloyds-grey-200)",

  "review-page-header-color": "var(--white)",
  "review-page-header-background-color": "var(--primary)",
  "review-page-section-border": "1px solid var(--grey-10)",
  "inactive-button-icon-color": "#F6F6F6",

  "flight-list-grid-filter-selected-background-color": "var(--primary)",
  "flight-list-grid-filter-selected-color": "var(--white)",

  // CALENDAR COLORS
  "calendar-labels-background-color": "#DDD",
  "date-selected-text-color": "#FFFFFF",
  "date-hover-text-color": "#FFFFFF",
  "bucket-0-text-color": "#FFFFFF",
  "bucket-1-text-color": "#FFFFFF",
  "bucket-2-text-color": "#FFFFFF",
  "bucket-3-text-color": "#FFFFFF",
  "bucket-0-color": "#77BA00",
  "bucket-1-color": "#FAA424",
  "bucket-2-color": "#FA6424",
  "bucket-3-color": "#D92049",
  "white-font-color": "rgba(255, 255, 255, 1.0)",
  "grey-font-color": "rgba(80, 80, 80, 1.0)",
  "date-hover-color": "rgba(0, 118, 193, 1.0)",
  "date-selected-color": "rgba(0, 118, 193, 1.0)",
  "date-range-limit-color": "rgba(0, 118, 193, 1.0)",
  "date-disabled-color": "#F4f4f4",
  "date-selected-background-color": "var(--white)",

  "flight-search-bar-text-primary": "var(--text-primary)",
};

const SharedLibVariables = {
  "primary-text": "var(--text-primary)",
  "secondary-text": "var(--lloyds-text-subdued)",
  secondary: "#FF0000",
};

const ThemeVariable = {
  /////////////////////
  //     HTS EU Vars    //
  /////////////////////

  "text-subdued": "#767676",

  /** Icons */
  "icon-primary": "var(--primary)",
  "icon-secondary": "var(--lloyds-grey-400)",
  "icon-subdued": "var(--lloyds-grey-300)",

  success: "var(--lloyds-green-500)",
  critical: "var(--lloyds-red-500)",
  warning: "var(--lloyds-orange-500)",
  info: "var(--lloyds-blue-500)",
  "success-light": "var(--lloyds-green-100)",
  "critical-light": "var(--lloyds-red-100)",
  "warning-light": "var(--lloyds-orange-100)",
  "info-light": "var(--lloyds-blue-100)",

  "background-color-subdued": "var(--lloyds-grey-100)",

  "bucket-0-color-high-contrast": "#EEF6E9",
  "bucket-1-color-high-contrast": "#00B282",
  "bucket-2-color-high-contrast": "#036646",
  "bucket-3-color-high-contrast": "#111111",

  // Colors
  "error-color": "var(--lloyds-critical)",
  "success-color": "var(--lloyds-success)",
  // Radii
  "border-radius": "2px",
  "border-radius-sm": "4px",
  "border-radius-md": "6px",
  "border-radius-lg": "8px",
  "border-radius-xl": "16px",

  // Dividers
  "divider-color": "var(--lloyds-grey-100)",
  "divider-color-2": "#E5E5E5",
  "divider-border": "1px solid var(--divider-color)",
  // Inputs
  "input-border": "1px solid var(--lloyds-grey-200)",
  "input-border-radius": "0",
  // RadioSelectRow
  "radio-select-border": "2px solid var(--primary)",
  "radio-select-border-radius": "var(--border-radius-sm)",
  "radio-disabled-border": "2px solid var(-lloyds-grey-400)",
  // Mobile Popover
  "mobile-popover-border-radius": "var(--border-radius-xl)",
  "mobile-popover-title-color": "var(--text-secondary)",
  "mobile-popover-subtitle-color": "var(--lloyds-grey-400)",
  // Calendar
  "date-selected-color": "var(--primary)",
  "date-selected-text-color": "var(--lloyds-primary-4)",
  "date-text-color": "var(--text-primary)",
  "date-hover-color": "var(--primary)",
  "date-disabled-color": "#F4f4f4",
  // header
  "header-desktop-height": "76px",
  "header-mobile-height": "56px",
  "header-primary": "var(--white)",
  "header-travel-destination": "var(--text-subdued)",
  "header-travel-passenger": "var(--text-subdued)",

  "tabbed-search-title": "var(--white)",
  // footer
  "footer-desktop-height": "50px",
  "footer-mobile-height": "48px",
  // Cards
  "card-page-background": "var(--white)",
  "card-border": "2px solid var(--lloyds-grey-100)",
  "card-border-radius-mobile": "var(--border-radius)",
  "card-border-radius-desktop": "var(--border-radius-sm)",
  "card-tag-text": "var(--text-primary)",
  "card-tag-background": "var(--lloyds-grey-100)",
  "card-tag-border-radius": "var(--border-radius-sm)",
  // Spinner
  "spinner-top-border-color-primary": "rgba(255, 255, 255, 0.30)",
  "spinner-border-color-primary": "var(--white)",
  "spinner-top-border-color-secondary": "var(--primary)",
  "spinner-border-color-secondary": "rgba(0, 106, 77, .25)",
  "spinner-top-border-color-disabled": "var(--lloyds-grey-300)",
  "spinner-border-color-disabled": "var(--lloyds-grey-200)",
  // Feature Tags
  "feature-background": "var(--lloyds-green-100)",
  "feature-text": "var(--primary)",
  "feature-padding": "5px 6px",
  "feature-gap": "6px",
  "feature-border-radius": "var(--border-radius-sm)",
  // Flights
  //
  // Cards
  "flight-card-border-color": "var(--lloyds-grey-200)",
  "flight-card-border": "1px solid var(--flight-card-border-color)",
  "flight-card-border-radius": "var(--border-radius-sm)",

  // B2B Loading Popup
  "loading-popup": "white",

  // My trips
  "schedule-change-minor-color": "#ffdfa5",
  "schedule-change-major-color": "var(--lloyds-red-500)",

  /////////////////////
  //     Buttons     //
  /////////////////////
  //All
  "button-font": "LloydsBankJack",
  "button-border-radius": "0px",
  // Primary
  "primary-button-background": "var(--primary)",
  "primary-button-background-pressed": "var(--lloyds-primary-2)",
  "primary-button-background-disabled": "var(--grey-disabled)",
  "primary-button-font-color": "var(--white)",
  "primary-button-font-color-disabled": "var(--lloyds-grey-200)",
  // Secondary
  "secondary-button-border": "2px solid var(--primary)",
  "secondary-button-border-disabled": "2px solid #BFBFBF",
  "secondary-button-background-pressed": "#99c3b7",
  "secondary-button-font-color": "var(--primary)",
  "secondary-button-font-color-disabled": "var(--lloyds-grey-200)",
  // Tertiary
  "tertiary-button-border": "1px solid var(--lloyds-grey-200)",
  "tertiary-button-border-disabled": "2px solid #BFBFBF",
  "tertiary-button-background-pressed": "#99c3b7",
  "tertiary-button-font-color": "var(--primary)",
  "tertiary-button-font-color-disabled": "var(--lloyds-grey-200)",

  // Large
  "large-button-font-size": "18px",
  "large-button-font-weight": "500",
  "large-button-line-height": "24px",
  "large-button-max-width": "343px",
  "large-button-padding": "12px 32px",
  // Small
  "small-button-font-size": "16px",
  "small-button-font-weight": "400",
  "small-button-line-height": "18px",
  "small-button-padding": "10px 30px",

  // Floating box
  "floating-box-padding": "16px",
  "floating-box-background": "var(--white)",
  "floating-box-box-shadow": "0px -12px 24px -8px rgba(0, 0, 0, 0.05)",

  // Customer Chat Support
  "chat-bg-customer": "var(--primary)",

  "success-tick-color": "#00847F",
};

const createMUIBaseline = (variables: { [key: string]: string }) =>
  ({
    breakpoints: {
      values: {
        xs: 0,
        sm: 480,
        md: 960,
        lg: 1200,
        xl: 1440,
      },
    },
    palette: {
      type: "light",
      common: {
        black: variables.black,
        white: variables.white,
      },
      background: {
        default: variables.background,
        paper: variables.white,
      },
      primary: {
        light: variables.primary,
        dark: variables.primary,
        main: variables.primary,
        contrastText: variables.white,
      },
      secondary: {
        light: variables.secondary,
        main: variables.secondary,
        contrastText: variables.white,
      },
      text: {
        primary: variables["grey-1"],
        secondary: variables["gray-80"],
        disabled: variables["text-subdued"],
        hint: "#FFF",
      },
      action: {
        disabled: variables["gray-80"],
      },
      error: {
        main: variables["lloyds-red-500"],
      },
      divider: variables["gray-30"],
    },
    typography: {
      fontSize: 16,
      h1: {
        fontSize: 32,
        fontWeight: 400,
      },
      h2: {
        fontSize: 24,
        fontWeight: 400,
      },
      h3: {
        fontSize: 24,
        fontWeight: 700,
      },
      h4: {
        fontSize: 22,
        fontWeight: 700,
      },
      h5: {
        fontSize: 18,
        fontWeight: 700,
      },
      h6: {
        fontSize: 16,
        fontWeight: 700,
      },
      subtitle1: {
        fontSize: 16,
        fontWeight: 500,
      },
      subtitle2: {
        fontSize: 14,
        fontWeight: 400,
      },
      body1: {
        fontSize: 16,
        fontWeight: 400,
      },
      body2: {
        fontSize: 14,
        fontWeight: 400,
      },
      caption: {
        fontSize: 14,
        fontWeight: 400,
      },
      button: {
        lineHeight: "1.25rem",
        textTransform: "none" as const,
        fontWeight: 400,
      },
    },
    shape: {
      borderRadius: 2,
    },
    spacing: [0, 4, 8, 12, 16, 24, 32, 64, 0, 0, 0],
    props: {
      MuiTextField: {
        variant: "filled",
        fullWidth: true,
      },
      MuiSelect: {
        variant: "filled",
        fullWidth: true,
      },
      MuiFormControl: {
        fullWidth: true,
      },
      MuiFilledInput: {
        disableUnderline: true,
        fullWidth: true,
      },
      MuiPaper: {
        elevation: 0,
      },
      MuiRadio: {
        color: "primary",
        size: "small",
      },
      MuiCheckbox: {
        color: "primary",
      },
      MuiCard: {
        variant: "outlined",
      },
      MuiButton: {
        variant: "contained",
      },
      MuiAppBar: {
        color: "primary",
        variant: "elevation",
        elevation: 0,
        position: "relative",
      },
    },
    overrides: {
      MuiDivider: {
        root: {
          backgroundColor: variables["divider-color"],
        },
      },
      MuiFab: {
        root: {
          backgroundColor: variables.background,
          color: variables["text-subdued"],
        },
      },
      MuiButton: {
        root: {
          height: "unset",
          fontSize: 16,
          fontWeight: 600,
        },
        sizeSmall: {
          height: "unset",
          fontSize: 14,
        },
        sizeLarge: {
          height: "unset",
          fontWeight: 500,
          fontSize: 18,
        },
        iconSizeSmall: {
          "& > *:first-child": {
            fontSize: 12,
          },
        },
        outlined: {
          backgroundColor: variables.white,
          borderColor: variables["text-primary"],
        },
        outlinedPrimary: {
          borderColor: variables.primary,
        },
        text: {
          height: "unset",
        },
        contained: {
          backgroundColor: variables.background,
          color: variables["text-subdued"],
        },
      },
      MuiIconButton: {
        sizeSmall: {
          fontSize: "1rem",
          padding: 8,
        },
      },
      MuiLink: {
        root: {
          "&[disabled]": {
            color: variables["primary-disabled"],
            pointerEvents: "none",
          },
        },
        underlineHover: {
          "&:hover": {
            "text-decoration": "none",
          },
        },
      },
      MuiPaper: {
        root: {
          color: variables["text-primary"],
        },
      },
      MuiInputBase: {
        root: {
          fontSize: 16,
        },
        input: {
          color: variables["text-primary"],
        },
      },
      MuiInputAdornment: {
        root: {
          marginTop: "unset !important",
        },
        positionEnd: {
          marginLeft: 2,
        },
      },
      MuiFilledInput: {
        root: {
          color: variables["text-primary"],
          backgroundColor: variables.background,
          borderRadius: "var(--input-border-radius) !important",
          border: "var(--input-border)",
          "&:hover, &.Mui-focused, &.Mui-hover": {
            borderColor: variables["success"],

            "&:not(.Mui-disabled)": {
              backgroundColor: variables.background,
            },
          },
          "&$error": {
            borderColor: variables["lloyds-red-500"],
          },
        },
        input: {
          paddingLeft: 16,
          paddingRight: "26px !important",
          paddingTop: 16,
          paddingBottom: 16,
        },
        inputMarginDense: {
          paddingTop: 10,
          paddingBottom: 10,
        },
        adornedEnd: {
          paddingRight: 16,
        },
      },
      MuiSelect: {
        selectMenu: {
          fontSize: 16,
        },
        icon: {
          display: "none",
        },
      },
      MuiFormLabel: {
        root: {
          "&:hover, &.Mui-focused, &.Mui-hover": {
            color: variables["text-primary"],
          },
        },
      },
      MuiAutocomplete: {
        inputRoot: {
          "&[class*='MuiFilledInput-root']": {
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 12,
          },
          '&[class*="MuiFilledInput-root"]': {
            "& .ptBaseModule-MuiAutocomplete-input": {
              paddingTop: 10,
              paddingLeft: 0,
              paddingRight: 12,
              paddingBottom: 10,
            },
          },
        },
      },
      MuiFormHelperText: {
        root: {
          fontSize: 15,
          fontWeight: 400,

          "&$error": {
            backgroundColor: alpha(variables["lloyds-red-500"], 0.1),
            color: variables["text-primary"],
          },
          "&$success": {
            backgroundColor: alpha(variables["lloyds-red-500"], 0.1),
            color: variables["text-primary"],
          },
        },
        contained: {
          margin: 0,
          marginLeft: 0,
          marginRight: 0,
          padding: "8px 16px",
        },
        filled: {
          margin: 0,
          marginLeft: 0,
          marginRight: 0,
          padding: "8px 16px",
        },
      },
      MuiInputLabel: {
        root: {
          color: variables["text-primary"],
          fontSize: 16,

          "&$error": {
            color: variables["text-primary"],
          },
        },
        formControl: {
          transform: "none !important",
          position: "unset",
          paddingBottom: 8,
        },
        filled: {
          transform: "none !important",
          position: "unset",
          paddingBottom: 8,
        },
      },
      MuiOutlinedInput: {
        root: {
          backgroundColor: variables.background,
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          "&.Mui-checked": {
            color: variables.primary,
          },
        },
      },
      MuiDialog: {
        paper: {
          borderRadius: variables["border-radius"],
        },
      },
      MuiDialogActions: {
        root: {
          padding: "1rem",
        },
      },
      MuiChip: {
        sizeSmall: {
          fontSize: 14,
        },
      },
      MuiCardContent: {
        root: {
          padding: 10,
          "&:last-child": {
            paddingBottom: 10,
          },
        },
      },
      MuiToolbar: {
        gutters: {
          paddingLeft: 16,
          paddingRight: 16,
        },
      },
    },
  } as ThemeOptions);

const LloydsFonts = {
  typography: {
    fontFamily: "LloydsBankJack",
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          color: "var(--text-primary)",
        },
        "@font-face": [
          {
            fontFamily: "LloydsBankJack",
            fontWeight: 300,
            src: `
              url(${LloydsLight}) format("woff2");
            `,
          },
          {
            fontFamily: "LloydsBankJack",
            fontWeight: 400,
            src: `
              url(${LloydsRegular}) format("woff2");
            `,
          },
          {
            fontFamily: "LloydsBankJack",
            fontWeight: 500,
            src: `
              url(${LloydsMedium}) format("woff2");
            `,
          },
          {
            fontFamily: "LloydsBankJack",
            fontWeight: 700,
            src: `
              url(${LloydsBold}) format("woff2");
            `,
          },
        ],
      },
    },
  },
};

export const LloydsVariablesOld = {
  ...defaultThemeConfigWithBasicColors,
  ...BrandColors,
  ...CoreColors,
  ...SharedLibVariables,
  ...ThemeVariable,
};

const muiBaseline = createMUIBaseline(LloydsVariablesOld);
export const LloydsThemeOld = createTheme(deepmerge(muiBaseline, LloydsFonts));
