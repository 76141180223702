import { CoreFlightsComponent, CORE_FLIGHTS_NAME } from "@b2bportal/core-types";

import { default as departurePageStyles } from "./components/DeparturePage/DeparturePage.module.scss";
import { default as returnPageStyles } from "./components/ReturnPage/ReturnPage.module.scss";
import {
  algomerchTagsStyles,
  fareListFareDetailsStyles,
  filterListStyles,
  filterPriceStyles,
  filtersStyles,
  flightCardFareListStyles,
  flightCardStyles,
  flightFareListItemStyles,
  flightSummaryStyles,
  reviewFarePageStyles,
  reviewPageStyles,
  reviewHeaderStyles,
} from "./styles";

export const styleOverrides = {
  [CORE_FLIGHTS_NAME]: {
    [CoreFlightsComponent.DeparturePage]: departurePageStyles,
    [CoreFlightsComponent.ReturnPage]: returnPageStyles,
    [CoreFlightsComponent.FlightCard]: flightCardStyles,
    [CoreFlightsComponent.AlgomerchTags]: algomerchTagsStyles,
    [CoreFlightsComponent.FlightSummary]: flightSummaryStyles,
    [CoreFlightsComponent.ReviewFarePage]: reviewFarePageStyles,
    [CoreFlightsComponent.FareRestrictions]: reviewFarePageStyles,
    [CoreFlightsComponent.ReviewPage]: reviewPageStyles,
    [CoreFlightsComponent.ReviewBreakdown]: reviewPageStyles,
    [CoreFlightsComponent.FilterPrice]: filterPriceStyles,
    [CoreFlightsComponent.FareListFareDetails]: fareListFareDetailsStyles,
    [CoreFlightsComponent.FlightCardFareList]: flightCardFareListStyles,
    [CoreFlightsComponent.FlightFareGridItem]: flightFareListItemStyles,
    [CoreFlightsComponent.Filters]: filtersStyles,
    [CoreFlightsComponent.FilterList]: filterListStyles,
    [CoreFlightsComponent.ReviewFlightHeader]: reviewHeaderStyles,
  },
};
