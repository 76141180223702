import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FlightSearchActions,
  getAirlineFilter,
  getAirportFilter,
  getDepartureFiltersOptions,
  getDestination,
  getFareclassOptionFilter,
  getFlightNumberFilter,
  getMaxPriceFilter,
  getOrigin,
  getOutboundArrivalTimeRange,
  getOutboundDepartureTimeRange,
  getReturnArrivalTimeRange,
  getReturnDepartureTimeRange,
  getReturnFiltersOptions,
  getStopsOption,
  getTripCategory,
} from "@b2bportal/core-flights";
import {
  type ITimeRange,
  SliceStopCountFilter,
  TripCategory,
} from "@b2bportal/core-types";
import {
  FilterAirline,
  FilterAirport,
  type FilterStopsComponentProps,
  type IStopsOption,
} from "@components/flights";
import { FilterPrice, type FilterPriceValue } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { IconComponent, IconName } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { Accordion, LbgModal } from "@lloyds/ui-connected";
import { ActionButton, RadioGroup, TextButton } from "@lloyds/ui-core";
import { Box, IconButton } from "@material-ui/core";

import styles from "./FilterDialog.module.scss";
import { FilterTime } from "./FilterTime";

export const FilterDialog = ({ sliceIndex }: { sliceIndex: number }) => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();

  const dispatch = useDispatch();
  const origin = useSelector(getOrigin);
  const destination = useSelector(getDestination);
  const departureFilterOptions = useSelector(getDepartureFiltersOptions);
  const returnFilterOptions = useSelector(getReturnFiltersOptions);
  const tripCategory = useSelector(getTripCategory);

  const filterOptions =
    sliceIndex === 0 ? departureFilterOptions : returnFilterOptions;

  /** State data  */
  const stopsState = useSelector(getStopsOption);
  const priceState = useSelector(getMaxPriceFilter);
  const fareClassesState = useSelector(getFareclassOptionFilter);
  const airlineFilter = useSelector(getAirlineFilter);
  const airportFilter = useSelector(getAirportFilter);
  const outboundDepartureTimeRange = useSelector(getOutboundDepartureTimeRange);
  const outboundArrivalTimeRange = useSelector(getOutboundArrivalTimeRange);
  const returnDepartureTimeRange = useSelector(getReturnDepartureTimeRange);
  const returnArrivalTimeRange = useSelector(getReturnArrivalTimeRange);
  const flightNumberFilter = useSelector(getFlightNumberFilter);

  /** Component data  */
  const [open, setOpen] = useState(false);
  const [stop, setStop] = useState(stopsState);
  const [price, setPrice] = useState(priceState);
  const [fareClasses, setFareClasses] = useState(fareClassesState);
  const [airline, setAirline] = useState(airlineFilter);
  const [airport, setAirport] = useState(airportFilter);
  const [
    outboundDepartureTimeRangeInternal,
    setOutboundDepartureTimeRangeInternal,
  ] = useState(outboundDepartureTimeRange);
  const [
    outboundArrivalTimeRangeInternal,
    setOutboundArrivalTimeRangeInternal,
  ] = useState(outboundArrivalTimeRange);
  const [
    returnDepartureTimeRangeInternal,
    setReturnDepartureTimeRangeInternal,
  ] = useState(returnDepartureTimeRange);
  const [returnArrivalTimeRangeInternal, setReturnArrivalTimeRangeInternal] =
    useState(returnArrivalTimeRange);
  const [flightNumber, setFlightNumber] = useState(flightNumberFilter);

  const handleStopsChange = (newStop: SliceStopCountFilter) => {
    setStop(newStop);
  };

  const handlePriceChange = ({ maxPrice }: FilterPriceValue) => {
    setPrice(maxPrice);
  };

  const handleAirlineChange = (newAirlines: string[]) => {
    setAirline(newAirlines);
  };

  const handleAirportChange = (newAirports: string[]) => {
    setAirport(newAirports);
  };

  const handleOutboundTimeRangeChange = (
    outboundDepartureTimeRange: ITimeRange,
    outboundArrivalTimeRange: ITimeRange
  ) => {
    setOutboundDepartureTimeRangeInternal(outboundDepartureTimeRange);
    setOutboundArrivalTimeRangeInternal(outboundArrivalTimeRange);
  };
  const handleReturnTimeRangeChange = (
    returnDepartureTimeRange: ITimeRange,
    returnArrivalTimeRange: ITimeRange
  ) => {
    setReturnDepartureTimeRangeInternal(returnDepartureTimeRange);
    setReturnArrivalTimeRangeInternal(returnArrivalTimeRange);
  };

  const updateValuesToMatchState = useCallback(() => {
    setStop(stopsState);
    setAirline(airlineFilter);
    setAirport(airportFilter);
    setPrice(priceState);
    setFlightNumber(flightNumberFilter);
    setFareClasses(fareClassesState);
    setOutboundDepartureTimeRangeInternal(outboundDepartureTimeRange);
    setOutboundArrivalTimeRangeInternal(outboundArrivalTimeRange);
    setReturnDepartureTimeRangeInternal(returnDepartureTimeRange);
    setReturnArrivalTimeRangeInternal(returnArrivalTimeRange);
  }, [
    airlineFilter,
    airportFilter,
    stopsState,
    priceState,
    flightNumberFilter,
    fareClassesState,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
  ]);

  useEffect(() => {
    // Need it in case filter changes outside of this component
    updateValuesToMatchState();
  }, [updateValuesToMatchState]);

  const resetAllFilters = () => {
    dispatch(FlightSearchActions.resetFilters());
  };

  const applyFilter = useCallback(() => {
    dispatch(
      FlightSearchActions.setAllFilters({
        stopsOption: stop,
        maxPriceFilter: price,
        fareclassOptionFilter: fareClasses,
        airlineFilter: airline,
        airportFilter: airport,
        flightNumberFilter: flightNumber,
        outboundDepartureTimeRange: outboundDepartureTimeRangeInternal,
        outboundArrivalTimeRange: outboundArrivalTimeRangeInternal,
        returnDepartureTimeRange: returnDepartureTimeRangeInternal,
        returnArrivalTimeRange: returnArrivalTimeRangeInternal,
      })
    );
    setOpen(false);
  }, [
    price,
    stop,
    airline,
    dispatch,
    fareClasses,
    flightNumber,
    airport,
    outboundArrivalTimeRangeInternal,
    returnArrivalTimeRangeInternal,
    returnDepartureTimeRangeInternal,
    outboundDepartureTimeRangeInternal,
  ]);

  const onClose = useCallback(() => {
    updateValuesToMatchState();
    setOpen(false);
  }, [updateValuesToMatchState]);

  return (
    <>
      {matchesMobile ? (
        <IconButton
          size="small"
          className={styles.filterButton}
          onClick={() => {
            setOpen(true);
          }}
        >
          <IconComponent name={IconName.FilterMenuThick} />
        </IconButton>
      ) : (
        <button
          className={styles.button}
          onClick={() => {
            setOpen(true);
          }}
        >
          <IconComponent name={IconName.FilterMenuThick} />
          {t("searchFilter.allFilters")}
        </button>
      )}
      <LbgModal
        disableContentPadding
        open={open}
        onClose={onClose}
        title={
          <>
            {t("filters")}
            <Box ml={2} display="inline-block">
              <TextButton
                onClick={resetAllFilters}
                message={t("flight.searchFilter.reset")}
              />
            </Box>
          </>
        }
        primaryBottomButton={
          <ActionButton
            size={matchesMobile ? "large" : "small"}
            onClick={applyFilter}
            message={t("flight.searchFilter.apply")}
          />
        }
        secondaryBottomButton={
          !matchesMobile && (
            <ActionButton
              size="small"
              defaultStyle="h4r-secondary"
              message={t("cancel")}
              onClick={onClose}
            />
          )
        }
      >
        <Accordion title={t("flight.searchFilter.stops")}>
          <FilterStops value={stop} onStopFilterChange={handleStopsChange} />
        </Accordion>
        <Accordion
          title={t("flight.searchFilter.maxPrice")}
          subtitle={
            tripCategory === TripCategory.ONE_WAY
              ? t("flight.searchFilter.maxPriceSubheaderOneWay")
              : t("flight.searchFilter.maxPriceSubheaderRoundTrip")
          }
        >
          <FilterPrice
            label={t("searchFilter.maxPrice")}
            minimumPrice={filterOptions.priceMin}
            maximumPrice={filterOptions.priceMax}
            initialValue={{
              minPrice: filterOptions.priceMin?.value ?? 0,
              maxPrice: priceState,
            }}
            onFilterPriceChange={handlePriceChange}
          />
        </Accordion>
        <Accordion title={t("flight.searchFilter.airline")}>
          <FilterAirline
            allAirlines={filterOptions.airlineOptions}
            airlineFilter={airline}
            onAirlineFilterChange={handleAirlineChange}
          />
        </Accordion>
        {sliceIndex === 0 && (
          <Accordion
            title={t("flight.searchFilter.outboundTimes")}
            subtitle={t("flight.searchFilter.timeSubheader")}
          >
            <FilterTime
              origin={origin}
              destination={destination}
              departureTimeRange={outboundDepartureTimeRangeInternal}
              arrivalTimeRange={outboundArrivalTimeRangeInternal}
              onSliceTimeRangeChange={handleOutboundTimeRangeChange}
            />
          </Accordion>
        )}
        {tripCategory === TripCategory.ROUND_TRIP && (
          <Accordion
            title={t("flight.searchFilter.returnTimes")}
            subtitle={t("flight.searchFilter.timeSubheader")}
          >
            <FilterTime
              origin={destination}
              destination={origin}
              departureTimeRange={returnDepartureTimeRangeInternal}
              arrivalTimeRange={returnArrivalTimeRangeInternal}
              onSliceTimeRangeChange={handleReturnTimeRangeChange}
            />
          </Accordion>
        )}

        <Accordion title={t("flight.searchFilter.airport")}>
          <FilterAirport
            allAirports={filterOptions.airportOptions}
            airportFilter={airport}
            onAirportFilterChange={handleAirportChange}
          />
        </Accordion>
      </LbgModal>
    </>
  );
};

export const FilterStops = (props: FilterStopsComponentProps) => {
  const { value, onStopFilterChange } = props;
  const { t } = useI18nContext();

  const stopsOptions: IStopsOption[] = [
    {
      label: t("flight.searchFilter.anyStop"),
      value: SliceStopCountFilter.ANY_NUMBER,
    },
    {
      label: t("flight.searchFilter.noStops"),
      value: SliceStopCountFilter.NONE,
    },
    {
      label: t("flight.searchFilter.oneOrFewerStops"),
      value: SliceStopCountFilter.ONE_OR_LESS,
    },
    {
      label: t("flight.searchFilter.twoOrFewerStops"),
      value: SliceStopCountFilter.TWO_OR_LESS,
    },
  ];

  return (
    <RadioGroup
      value={value}
      options={stopsOptions}
      onChange={onStopFilterChange}
    />
  );
};
