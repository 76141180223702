import { useI18nContext } from "@hopper-b2b/i18n";
import clsx from "clsx";
import "./RadioInput.scss";

export const RadioInput = ({
  checked,
  onClick,
  disabled,
  className,
}: {
  checked: boolean;
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}) => {
  const { t } = useI18nContext();

  return (
    <input
      className={clsx(className, "radio", {
        checked: checked,
        disabled: disabled,
      })}
      checked={checked}
      onClick={onClick}
      readOnly
      type="radio"
      aria-label={t("radioInput")}
    />
  );
};
