import type { Brand } from "@b2bportal/lloyds-auth-api";
import type { ITenantContextProps } from "@hopper-b2b/utilities";

import { getBrandIcons } from "./assets/icons";
import { lightModeImages } from "./assets/images";

export const generateTenantContext = (brand: Brand): ITenantContextProps => ({
  icons: getBrandIcons(brand),
  images: lightModeImages(brand),
});
