import { useState } from "react";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { Grid, InputAdornment } from "@material-ui/core";
import dayjs from "dayjs";
import { TextInputField } from "../TextInputField";

export interface DatePickerButtonProps {
  dateFormat?: string;
  startDate: Date | null;
  endDate: Date | null;
  disabled?: boolean;
  startLabel: string;
  endLabel: string;
  renderCalendarPopup?: (extraProps: {
    open: boolean;
    closePopup: () => void;
  }) => React.ReactElement;
  onClick?: () => void;
  hideEndDate?: boolean;
}

export const DatePickerButton = (props: DatePickerButtonProps) => {
  const {
    startDate,
    endDate,
    startLabel,
    endLabel,
    disabled,
    renderCalendarPopup,
    onClick,
    dateFormat,
    hideEndDate,
  } = props;

  const icons = useTenantIcons();

  const [openModal, setOpenModal] = useState(false);

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Grid spacing={3} container direction="row" alignItems="stretch">
      <Grid item xs={hideEndDate ? 12 : 6}>
        <TextInputField
          placeholder={startLabel}
          disabled={disabled}
          value={startDate ? dayjs(startDate).format(dateFormat) : undefined}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {<img src={icons.calendar} alt="" />}
              </InputAdornment>
            ),
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleClick();
              onClick?.();
            }
          }}
          onClick={
            disabled
              ? undefined
              : () => {
                  handleClick();
                  onClick?.();
                }
          }
        />
      </Grid>
      {!hideEndDate && (
        <Grid item xs={6}>
          <TextInputField
            placeholder={endLabel}
            disabled={disabled}
            value={endDate ? dayjs(endDate).format(dateFormat) : undefined}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {<img src={icons.calendar} alt="" />}
                </InputAdornment>
              ),
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleClick();
                onClick?.();
              }
            }}
            onClick={
              disabled
                ? undefined
                : () => {
                    handleClick();
                    onClick && onClick();
                  }
            }
          />
        </Grid>
      )}
      {renderCalendarPopup && (
        <div>
          {renderCalendarPopup({
            open: openModal,
            closePopup: handleClose,
          })}
        </div>
      )}
    </Grid>
  );
};
