import { useMemo } from "react";
import { useExperiment } from "@hopper-b2b/experiments";
import { ExperimentVariant } from "@hopper-b2b/types";
import type {
  AirFeatureFlagsContextProps,
  CommonFeatureFlagsContextProps,
  LodgingFeatureFlagsContextProps,
  TripsFeatureFlagsContextProps,
} from "@hopper-b2b/utilities";

import { FEATURE_FLAGS } from "../constants";

export const useAirEnabled = (): boolean =>
  useExperiment(FEATURE_FLAGS.AIR, ExperimentVariant.Available, true);

export const useLodgingEnabled = (): boolean | undefined =>
  useExperiment(FEATURE_FLAGS.LODGING, ExperimentVariant.Available, true);

export const useWalletEnabled = (): boolean =>
  useExperiment(FEATURE_FLAGS.WALLET);

export const usePricePredictionEnabled = (): boolean =>
  useExperiment(FEATURE_FLAGS.PRICE_PREDICTION);
export const usePriceDropProtectionEnabled = (): boolean =>
  useExperiment(FEATURE_FLAGS.PRICE_DROP_PROTECTION);

export const useRecentFlightSearches = (): boolean =>
  useExperiment(FEATURE_FLAGS.RECENT_FLIGHT_SEARCHES);
export const useRecentHotelSearches = (): boolean =>
  useExperiment(FEATURE_FLAGS.RECENT_HOTEL_SEARCHES);

// TODO: Delete once rebrand is the main style
export const useNewLlodysStyle = (): boolean =>
  useExperiment(FEATURE_FLAGS.NEW_LLOYDS_STYLE);

export const useCommonFeatureFlags = (): CommonFeatureFlagsContextProps => {
  const enableWallet = useWalletEnabled();

  const featureFlags = useMemo(
    () =>
      ({
        enableCfar: true,
        enableWallet: enableWallet,
      } as CommonFeatureFlagsContextProps),
    [enableWallet]
  );

  return featureFlags;
};

export const useAirFeatureFlags = (): AirFeatureFlagsContextProps => {
  const commonFeatureFlags = useCommonFeatureFlags();
  const enablePricePrediction = usePricePredictionEnabled();
  const enablePriceDropProtection = usePriceDropProtectionEnabled();

  const featureFlags = useMemo(
    () =>
      ({
        ...commonFeatureFlags,
        enableAirShopV4: true,
        enableHFv2: true,
        enablePricePrediction,
        enablePriceDropProtection,
      } as AirFeatureFlagsContextProps),
    [commonFeatureFlags, enablePriceDropProtection, enablePricePrediction]
  );

  return featureFlags;
};

export const useLodgingFeatureFlags = (): LodgingFeatureFlagsContextProps => {
  const commonFeatureFlags = useCommonFeatureFlags();
  const featureFlags = useMemo(
    () =>
      ({
        ...commonFeatureFlags,
        showHorizontalScrollListInMobileMap: true,
      } as LodgingFeatureFlagsContextProps),
    [commonFeatureFlags]
  );

  return featureFlags;
};

export const useTripsFeatureFlags = (): TripsFeatureFlagsContextProps => {
  const commonFeatureFlags = useCommonFeatureFlags();
  const featureFlags = useMemo(
    () =>
      ({
        ...commonFeatureFlags,
        enableAirTripSearch: true,
      } as TripsFeatureFlagsContextProps),
    [commonFeatureFlags]
  );

  return featureFlags;
};
