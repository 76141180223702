import { disruptionRootReducer } from "@b2bportal/core-disruption";
import { flightsRootReducer } from "@b2bportal/core-flights";
import { cfarMiddlewares, cfarRootReducer } from "@b2bportal/core-flights-cfar";

import { authReducer } from "@hopper-b2b/self-serve";
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

const middleware = [...cfarMiddlewares];

const store = configureStore({
  reducer: {
    auth: authReducer,
    flights: flightsRootReducer,
    cfar: cfarRootReducer,
    disruption: disruptionRootReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
