import { useEffect, useMemo } from "react";
import { useExperiment } from "@hopper-b2b/experiments";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useIsSessionAuthenticated } from "@hopper-b2b/utilities";
import { FEATURE_FLAGS } from "@lloyds/theming";
import { StatusMessage } from "@lloyds/ui-core";
import { useWallet } from "@lloyds/wallet";
import { Container } from "@material-ui/core";

export const WalletNotification = () => {
  const { formatFiatCurrency, t } = useI18nContext();
  const isAuthenticated = useIsSessionAuthenticated();
  const isWalletEnabled = useExperiment(FEATURE_FLAGS.WALLET);

  const { creditBalance, isLoadingWallet, fetchAndSetWalletSummary } =
    useWallet();

  useEffect(() => {
    isAuthenticated && isWalletEnabled && fetchAndSetWalletSummary();
  }, [fetchAndSetWalletSummary, isAuthenticated, isWalletEnabled]);

  const absAmount = useMemo(() => {
    if (creditBalance) {
      return {
        ...creditBalance,
        value: Math.abs(creditBalance.value),
      };
    }
  }, [creditBalance]);

  const showNotification = useMemo(() => {
    return creditBalance?.value > 0 && !isLoadingWallet;
  }, [creditBalance, isLoadingWallet]);

  return (
    <Container disableGutters maxWidth="xl">
      {showNotification && (
        <StatusMessage
          type="info"
          icon="wallet"
          message={t("wallet.headerBanner", {
            amount: formatFiatCurrency(absAmount),
          })}
        />
      )}
    </Container>
  );
};
