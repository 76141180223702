import {
  lloydsWalletApi,
  type GetVouchersResponseSuccess,
  GetVouchersResponseEnum,
  type GetVouchersRequest,
  OfferType,
} from "@b2bportal/lloyds-wallet-api";
import { axiosInstance } from "@hopper-b2b/api";
import {
  PATH_CARS,
  PATH_FLIGHTS,
  PATH_HOTELS_ROOT,
} from "@hopper-b2b/utilities";

export const fetchWalletVouchers =
  async (): Promise<GetVouchersResponseSuccess> => {
    const pathName = window.location.pathname;

    let offerType: OfferType;
    switch (true) {
      case pathName.includes(PATH_FLIGHTS):
        offerType = OfferType.FlightOffer;
        break;
      case pathName.includes(PATH_HOTELS_ROOT):
        offerType = OfferType.LodgingOffer;
        break;
      case pathName.includes(PATH_CARS):
        offerType = OfferType.CarOffer;
        break;
      default:
        offerType = OfferType.GeneralOffer;
        break;
    }
    const getVouchersRequest: GetVouchersRequest = {
      requiredOfferType: offerType,
    };
    return new Promise((resolve, reject) => {
      lloydsWalletApi(axiosInstance)
        .apiV0WalletVouchersPut(getVouchersRequest)
        .then((res) => {
          switch (res.data.GetVouchersResponse) {
            case GetVouchersResponseEnum.Success: {
              resolve(res.data);
              break;
            }
            case GetVouchersResponseEnum.Failure:
            default:
              reject(res.data);
              break;
          }
        })
        .catch((e) => {
          reject(e);
        });
    });
  };
