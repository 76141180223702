import { ActionLink } from "@hopper-b2b/ui";
import { Box, Typography } from "@material-ui/core";

import clsx from "clsx";

import { ReactComponent as Plus } from "./add.svg";
import { ReactComponent as Minus } from "./minus.svg";
import styles from "./styles.module.scss";

export const CountPicker = (props: {
  title: string;
  subtitle?: string;
  minimumCount?: number;
  maximumReached?: boolean;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const {
    title,
    subtitle,
    minimumCount,
    count,
    setCount,
    maximumReached = false,
  } = props;

  const minusDisabled = count <= (minimumCount ?? 0);

  return (
    <Box
      borderBottom={"var(--divider-border)"}
      display="flex"
      flexDirection="row"
      justifyContent={"space-between"}
      paddingX="24px"
      paddingY="19px"
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Typography className={styles.countPickerTitle} variant="inherit">
          {title}
        </Typography>
        <Typography className={styles.countPickerSubtitle} variant="inherit">
          {subtitle}
        </Typography>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"row"}
        alignContent={"center"}
        justifyContent={"flex-end"}
      >
        <ActionLink
          label={`Decrease ${title} ${subtitle || ""} Traveler`}
          disabled={minusDisabled}
          onClick={() => {
            if (!minusDisabled) {
              setCount(count - 1);
            }
          }}
          content={
            <Box
              display="flex"
              justifyContent="center"
              alignItems={"center"}
              height={"48px"}
              width={"48px"}
              borderRadius={"50%"}
              className={styles.buttonContentWrapper}
            >
              <PlusMinusControl type="minus" disabled={minusDisabled} />
            </Box>
          }
        />
        <Box
          width={"42px"}
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems={"center"}
        >
          <Typography
            aria-live="polite"
            aria-atomic
            variant="inherit"
            className={styles.count}
            aria-label={
              subtitle ? `${count} ${title} ${subtitle}` : `${count} ${title}`
            }
          >
            {count}
          </Typography>
        </Box>
        <ActionLink
          label={`Increase ${title} ${subtitle || ""} Traveler`}
          onClick={() => setCount(count + 1)}
          disabled={maximumReached}
          content={
            <Box
              display="flex"
              justifyContent="center"
              alignItems={"center"}
              height={"48px"}
              width={"48px"}
              borderRadius={"50%"}
              className={styles.buttonContentWrapper}
            >
              <PlusMinusControl type="plus" disabled={maximumReached} />
            </Box>
          }
        />
      </Box>
    </Box>
  );
};

const PlusMinusControl = ({
  type,
  disabled,
}: {
  type: "plus" | "minus";
  disabled: boolean;
}) => {
  return type === "plus" ? (
    <Plus
      className={clsx(
        styles["plus-minus-icon"],
        disabled ? styles.disabled : ""
      )}
    />
  ) : (
    <Minus
      className={clsx(
        styles["plus-minus-icon"],
        disabled ? styles.disabled : ""
      )}
    />
  );
};
