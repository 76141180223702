import { useModuleBEM } from "@b2bportal/core-themes";
import { useI18nContext } from "@hopper-b2b/i18n";
import { ButtonWrap } from "@hopper-b2b/ui";
import { useDeviceTypes } from "@hopper-b2b/utilities";
import { ActionButton } from "@lloyds/ui-core";
import { useHighContrast } from "@lloyds/utils";
import { Typography } from "@material-ui/core";

import styles from "./HighContrastToggle.module.scss";

export const HighContrastToggle = () => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const [block, cn] = useModuleBEM(styles, "HighContrastToggle");

  const { isHighContrast, toggleHighContrast } = useHighContrast();

  return matchesMobile ? (
    <ButtonWrap className={block} onClick={toggleHighContrast}>
      <Typography variant="inherit" className={cn("label")}>
        {t("accessibility.highContrast.label")}
      </Typography>
      <Typography variant="inherit" className={cn("indicator")}>
        {t(`accessibility.highContrast.${isHighContrast ? "on" : "off"}`)}
      </Typography>
    </ButtonWrap>
  ) : (
    <ActionButton
      size="small"
      defaultStyle="h4r-secondary"
      message={t(
        `accessibility.highContrast.button${isHighContrast ? "Off" : "On"}`
      )}
      onClick={toggleHighContrast}
    />
  );
};
