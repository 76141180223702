import { useCallback } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FloatingBox } from "@hopper-b2b/ui";
import { ActionButton } from "@lloyds/ui-core";
import { Grid } from "@material-ui/core";

import { DeleteButton } from "../DeleteButton";

export type MobileTravelerFormButtonsProps = {
  travelerId?: string;
  isEdit?: boolean;
  disabled: boolean;
  onSave: () => void;
  onDelete: (travelerId: string) => void;
};

export const MobileTravelerFormButtons = ({
  travelerId,
  disabled,
  onSave,
  onDelete,
}: MobileTravelerFormButtonsProps) => {
  const { t } = useI18nContext();

  const handleDelete = useCallback(() => {
    travelerId && onDelete(travelerId);
  }, [onDelete, travelerId]);

  return (
    <FloatingBox>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <ActionButton
            disabled={disabled}
            onClick={onSave}
            message={travelerId ? t("saveChanges") : t("savePassenger")}
          />
        </Grid>
        {travelerId && (
          <Grid item>
            <DeleteButton
              id="traveler"
              message={t("delete")}
              title={t("deleteTraveller")}
              description={t("deleteTravellerDescription")}
              onClick={handleDelete}
              acceptButtonProps={{
                message: t("deleteTraveller"),
              }}
            />
          </Grid>
        )}
      </Grid>
    </FloatingBox>
  );
};
