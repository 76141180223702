import { Fragment, memo, useCallback, useState, type ReactNode } from "react";
import { ChatWindowContainer, testChatProvider } from "@hopper-b2b/chat";
import { useI18nContext } from "@hopper-b2b/i18n";
import { FloatingBox } from "@hopper-b2b/ui";
import { PATH_TRIPS, useDeviceTypes } from "@hopper-b2b/utilities";
import { Conversation } from "@lloyds/theming";
import { ActionButton, FullScreen } from "@lloyds/ui-core";
import { Box, Typography } from "@material-ui/core";

import { LbgModal, type ILbgModalProps } from "../LbgModal";

export type ContactSupportProps = ILbgModalProps &
  Omit<ContactSupportContentProps, "embedded">;

export const ContactSupport = (props: ContactSupportProps) => {
  const { t } = useI18nContext();

  return (
    <LbgModal
      title={t("mytrips.support.title")}
      subtitle={t("mytrips.support.subtitle")}
      disableContentPadding
      icon={<Conversation height={24} width={24} />}
      {...props}
    >
      <Box px={5} pb={4}>
        <ContactSupportContent embedded {...props} />
      </Box>
    </LbgModal>
  );
};

export const ContactSupportScreen = (
  props: Omit<ContactSupportContentProps, "embedded">
) => {
  const { t } = useI18nContext();

  return (
    <FullScreen
      title={t("mytrips.support.title")}
      icon={<Conversation height={48} width={48} />}
      subtitle={t("mytrips.support.subtitle")}
    >
      <ContactSupportContent {...props} />
    </FullScreen>
  );
};

export type ContactSupportContentProps = {
  embedded?: boolean;
  title?: ReactNode;
  message?: ReactNode;
  supportId: string;
};

export const ContactSupportContent = memo(
  ({ embedded, title, message, supportId }: ContactSupportContentProps) => {
    const { t, brand } = useI18nContext();
    const { matchesMobile } = useDeviceTypes();
    const { supportNumber } = brand.contactSupport;
    const [chatWindowOpen, setChatWindowOpen] = useState(false);

    const handleCallSupport = useCallback(() => {
      window.location.href = `tel:${supportNumber}`;
    }, [supportNumber]);

    const handleChatWithAssistant = useCallback(() => {
      setChatWindowOpen(true);
    }, []);

    const handleCloseChat = useCallback(() => {
      setChatWindowOpen(false);
    }, []);

    const getTripDetailsPath = useCallback(
      (tripId: string) => `${PATH_TRIPS}?tripId=${tripId}`,
      []
    );

    const ButtonWrapper = embedded ? FloatingBox : Fragment;

    return (
      <>
        {title && <Typography variant="h2">{title}</Typography>}
        <Box my={embedded ? 0 : 3}>
          <Typography>{message ?? t("mytrips.support.description")}</Typography>
        </Box>
        {supportId && (
          <Box my={3} textAlign="center">
            <Typography variant="h6">
              {t("mytrips.cancel.contactSupport.supportId")}
            </Typography>
            <Typography variant="h2" color="primary">
              {supportId}
            </Typography>
          </Box>
        )}
        <ButtonWrapper>
          <Box my={2}>
            <ActionButton
              message={t("mytrips.support.primaryCta")}
              onClick={handleChatWithAssistant}
            />
          </Box>
          <Box my={2}>
            <ActionButton
              message={t("mytrips.support.secondaryCta")}
              onClick={handleCallSupport}
              defaultStyle="h4r-secondary"
            />
          </Box>
        </ButtonWrapper>
        {chatWindowOpen && (
          <ChatWindowContainer
            chatProvider={testChatProvider}
            closeChat={handleCloseChat}
            minimizeChat={handleCloseChat}
            mobile={matchesMobile}
            open={true}
            getTripDetailsPath={getTripDetailsPath}
          />
        )}
      </>
    );
  }
);
