import {
  CardPaymentActions as BaseCardPaymentActions,
  CartActions as BaseCartActions,
  ContactInformationActions as BaseContactInformationActions,
  PassengerActions as BasePassengerActions,
  ReviewActions as BaseReviewActions,
  WalletActions as BaseWalletActions,
  getObjectKeysAsObject,
} from "@hopper-b2b/checkout";

import { cartBreakdownActions } from "./cartBreakdown";
import { contactInformationActions } from "./contactInformation";
import { walletCreditActions } from "./walletCredits";
import { walletOfferActions } from "./walletOffers";

export const Actions = {
  ...BaseCardPaymentActions,
  ...BaseCartActions,
  ...BaseContactInformationActions,
  ...BasePassengerActions,
  ...BaseReviewActions,
  ...BaseWalletActions,
  ...contactInformationActions,
  ...walletCreditActions,
  ...walletOfferActions,
  ...cartBreakdownActions,
};

export const ActionTypes = getObjectKeysAsObject(Actions);
