import {
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getGenericSupportId } from "@hopper-b2b/api";
import { useI18nContext } from "@hopper-b2b/i18n";
import { useIsSessionAuthenticated } from "@hopper-b2b/utilities";
import { ActionButton, FullScreen } from "@lloyds/ui-core";
import { PATH_TRAVEL_HUB } from "@lloyds/utils";
import { Box } from "@material-ui/core";

import { ContactSupport } from "../ContactSupport";

import styles from "./ErrorScreen.module.scss";
import { ReactComponent as WarningIcon } from "./warning.svg";

export type ErrorScreenProps = { message?: ReactNode };

export const ErrorScreen = ({ message }: ErrorScreenProps) => {
  const { t } = useI18nContext();

  const isLoggedIn = useIsSessionAuthenticated();

  const onRedirectTravelHub = useCallback(() => {
    window.location.href = PATH_TRAVEL_HUB;
  }, []);

  const title = useMemo(
    () =>
      isLoggedIn ? t("genericErrorTitle") : t("expiredSessionModal.title"),
    [isLoggedIn, t]
  );

  const subtitle = useMemo(
    () =>
      message ?? isLoggedIn
        ? t("genericErrorSubheader")
        : t("expiredSessionModal.description"),
    [isLoggedIn, message, t]
  );

  return (
    <FullScreen
      icon={<WarningIcon className={styles.icon} height={48} width={48} />}
      title={title}
      subtitle={subtitle}
    >
      <>
        <Box my={2}>
          <ActionButton
            message={t("redirectLinksSection.travelHub")}
            onClick={onRedirectTravelHub}
          />
        </Box>
        {isLoggedIn && <ErrorScreenContactSupport />}
      </>
    </FullScreen>
  );
};

const fetchGenericSupportId = async (locale: string) => {
  try {
    return await getGenericSupportId(null, locale, "general", "general");
  } catch {
    return "--- ---";
  }
};

const ErrorScreenContactSupport = () => {
  const { t, language: locale } = useI18nContext();

  const [supportId, setSupportId] = useState("");
  const [contactSupportOpen, setContactSupportOpen] = useState(false);

  const openContactSupportModal = useCallback(() => {
    setContactSupportOpen(true);
  }, []);

  const closeContactSupportModal = useCallback(() => {
    setContactSupportOpen(false);
  }, []);

  useEffect(() => {
    fetchGenericSupportId(locale).then((supportId: string) => {
      setSupportId(supportId);
    });
  }, [locale]);

  return (
    <>
      <ActionButton
        defaultStyle="h4r-secondary"
        message={t("contactSupport")}
        onClick={openContactSupportModal}
      />
      <ContactSupport
        open={contactSupportOpen}
        onClose={closeContactSupportModal}
        supportId={supportId}
      />
    </>
  );
};
