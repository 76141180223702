import { useCallback } from "react";
import { useI18nContext } from "@hopper-b2b/i18n";
import type { IPaymentMethodRadio } from "@hopper-b2b/ui";

import { SelectionRow, SelectionType } from "../SelectionRow";

export const PaymentSelectRow = ({
  paymentMethod,
  selectedPaymentHopperId,
  cardEndingIn,
  disabled,
  selectPaymentMethod,
  removePaymentMethod,
}: Omit<IPaymentMethodRadio, "isMobile">) => {
  const { t } = useI18nContext();

  const onSelect = useCallback(() => {
    selectPaymentMethod(paymentMethod.id);
  }, [paymentMethod.id, selectPaymentMethod]);

  const onDelete = useCallback(() => {
    removePaymentMethod(paymentMethod.id);
  }, [paymentMethod.id, removePaymentMethod]);

  return (
    paymentMethod && (
      <SelectionRow
        type={SelectionType.RADIO}
        title={paymentMethod.cardType}
        description={cardEndingIn(paymentMethod)}
        selected={paymentMethod.id === selectedPaymentHopperId}
        disabled={disabled}
        onSelect={onSelect}
        deleteButtonProps={{
          id: "payment-method",
          message: t("delete"),
          title: t("deletePaymentMethod"),
          description: t("deletePaymentMethodDescription"),
          onClick: onDelete,
          acceptButtonProps: {
            message: t("deletePaymentMethod"),
          },
        }}
        ariaLabel={t("selectPayment")}
      />
    )
  );
};
