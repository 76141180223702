import { useMemo } from "react";
import { useLocation, useSearchParams } from "react-router-dom-v5-compat";
import {
  FLIGHTS_PATH,
  FLIGHTS_SEARCH_PATH,
  FLIGHTS_SHOP_PATH,
  FLIGHTS_SHOP_REVIEW_PATH,
  FLIGHT_BOOK_PATH,
  HOTELS_PATH,
  HOTELS_SEARCH_PATH,
} from "@b2bportal/core-utilities";
import { CheckoutQueryParams, ParentState } from "@hopper-b2b/checkout";
import { useI18nContext } from "@hopper-b2b/i18n";
import {
  PATH_AUTH_LOGOUT,
  PATH_AVAILABILITY_RELATIVE,
  PATH_HOME,
  PATH_HOTELS_ROOT,
  useDeviceTypes,
  useTenantImages,
} from "@hopper-b2b/utilities";
import { PATH_CLOSE } from "@lloyds/utils";
import { Box, CircularProgress, Dialog, Typography } from "@material-ui/core";
import clsx from "clsx";

import { ReactComponent as LloydsHorse } from "./horse.svg";
import styles from "./LoadingScreen.module.scss";

export const LoadingScreen = () => {
  const { t } = useI18nContext();
  const { matchesMobile } = useDeviceTypes();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const { title, message } = useMemo(
    () =>
      loadingText(pathname, searchParams, {
        retry: false,
        firstTimeOffer: false,
      }),
    [pathname, searchParams]
  );

  return (
    <LoadingScreenInternal
      title={t(title)}
      message={t(message)}
      mobile={matchesMobile}
      open
    />
  );
};

export const LOADING_TITLE_TEST_ID = "loading-title-test-id";
export const LOADING_MESSAGE_TEST_ID = "loading-message-test-id";

export type LoadingScreenProps = {
  title: string;
  message?: string;
  open: boolean;
  mobile: boolean;
};

const LoadingScreenInternal = ({
  title,
  message,
  mobile,
  open,
}: LoadingScreenProps) => {
  const images = useTenantImages();
  return (
    <Dialog
      BackdropProps={{
        invisible: false,
      }}
      classes={{
        paper: clsx(styles.paper, mobile ? styles.mobile : ""),
      }}
      hideBackdrop={false}
      open={open}
      transitionDuration={0}
    >
      <Box
        display="flex"
        flexDirection={mobile ? "column" : "row-reverse"}
        className={styles.container}
        justifyContent="center"
      >
        <Box height={mobile ? "38%" : "100%"} width={mobile ? "100%" : "50%"}>
          <img
            height={"100%"}
            width={"100%"}
            style={{
              position: "relative",
              objectFit: "cover",
            }}
            src={images.pageBackground}
            alt=""
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="start"
          justifyContent="space-between"
          height="100%"
          padding={mobile ? "48px 40px 70px 40px" : "40px 32px"}
        >
          <Box
            display="flex"
            width="100%"
            gridGap={16}
            flexDirection="row"
            alignItems="center"
          >
            <Box style={{ color: "var(--white)" }}>
              <CircularProgress color="inherit" />
            </Box>
            <Box>
              <Typography
                data-testid={LOADING_TITLE_TEST_ID}
                variant="h2"
                className={styles.title}
              >
                {title}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" gridGap={16}>
            <LloydsHorse height={40} width={45} />

            <Typography
              data-testid={LOADING_MESSAGE_TEST_ID}
              variant="inherit"
              className={styles.message}
            >
              {message}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

const loadingText = (
  pathname: string,
  search: URLSearchParams,
  options: {
    retry: boolean;
    firstTimeOffer: boolean;
  }
): { title: string; message: string } => {
  switch (true) {
    // Loading for flight route
    case pathname.includes(FLIGHTS_PATH):
      return flightLoadingText(pathname, search, options);
    // Loading for close route
    case pathname.includes(PATH_CLOSE):
      return {
        title: "loading.close.title",
        message: "loading.close.message",
      };
    // Loading for lodging route
    case pathname.includes(HOTELS_PATH):
      return lodgingLoadingText(pathname, search, options);
    // Loading for logout
    case pathname === `${PATH_HOME}${PATH_AUTH_LOGOUT}`:
      return {
        title: "loading.default.title",
        message: "loggingOut",
      };
    default:
      return {
        title: "loading.default.title",
        message: "loading.default.message",
      };
  }
};
const lodgingLoadingText = (
  pathname: string,
  search: URLSearchParams,
  options: {
    retry: boolean;
    firstTimeOffer: boolean;
  }
): { title: string; message: string } => {
  const checkoutState = search.get(CheckoutQueryParams.checkoutState);
  switch (true) {
    case pathname.includes(PATH_AVAILABILITY_RELATIVE):
    case pathname.includes(HOTELS_SEARCH_PATH):
      return {
        title: "loading.lodging.init.title",
        message: "loading.lodging.init.message",
      };
    case checkoutState === ParentState.passengerInformation:
    case checkoutState === ParentState.cardPayment:
      return {
        title: "loading.lodging.checkout.savingTravellerInfo.title",
        message: "loading.lodging.checkout.savingTravellerInfo.message",
      };
    // Loading for Checkout booking step
    case checkoutState === ParentState.review:
      if (options.retry) {
        return {
          title: "loading.lodging.checkout.bookingFailed.title",
          message: "loading.lodging.checkout.bookingFailed.message",
        };
      }
      return {
        title: "loading.lodging.checkout.booking.title",
        message: "loading.lodging.checkout.booking.message",
      };
    // Loading for Checkout entry
    case pathname.includes(PATH_HOTELS_ROOT + "book"):
      return {
        title: "loading.lodging.checkout.init.title",
        message: "loading.lodging.checkout.init.message",
      };
    default:
      return {
        title: "loading.default.title",
        message: "loading.default.message",
      };
  }
};

const flightLoadingText = (
  pathname: string,
  search: URLSearchParams,
  options: {
    retry: boolean;
    firstTimeOffer: boolean;
  }
): { title: string; message: string } => {
  const checkoutState = search.get(CheckoutQueryParams.checkoutState);
  switch (true) {
    case pathname.includes(FLIGHTS_SEARCH_PATH):
      return {
        title: "loading.flights.init.title",
        message: "loading.flights.init.message",
      };
    case pathname.includes(FLIGHTS_SHOP_REVIEW_PATH):
      return {
        title: "loading.flights.shopReview.title",
        message: "loading.flights.shopReview.message",
      };
    case pathname.includes(FLIGHTS_SHOP_PATH):
      return {
        title: "loading.flights.search.title",
        message: "loading.flights.search.message",
      };
    case checkoutState === ParentState.cardPayment:
    case checkoutState === ParentState.passengerInformation:
      return {
        title: "loading.flights.checkout.savingTravellerInfo.title",
        message: "loading.flights.checkout.savingTravellerInfo.message",
      };
    // Loading for Checkout booking step
    case checkoutState === ParentState.review:
      if (options.retry) {
        return {
          title: "loading.lodging.checkout.bookingFailed.title",
          message: "loading.lodging.checkout.bookingFailed.message",
        };
      }
      return {
        title: "loading.flights.checkout.booking.title",
        message: "loading.flights.checkout.booking.message",
      };
    case pathname.includes(FLIGHT_BOOK_PATH):
      return {
        title: "loading.flights.checkout.init.title",
        message: "loading.flights.checkout.init.message",
      };
    default:
      return {
        title: "loading.default.title",
        message: "loading.default.message",
      };
  }
};
