import { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom-v5-compat";
import {
  FLIGHT_ROUTES,
  suggestionToITripTerminus,
  terminusToSuggestion,
} from "@b2bportal/core-utilities";
import {
  FlightSearchHooks,
  useFlightSearch,
  useFlightRecentSearches,
} from "@components/flights";
import { useI18nContext } from "@hopper-b2b/i18n";
import { TripCategory } from "@hopper-b2b/types";
import { useTenantIcons } from "@hopper-b2b/utilities";
import {
  CalendarPickerButton,
  PassengerPickerInput,
  FlightCalendarPicker,
} from "@lloyds/ui-connected";
import { FlightLocationAutoComplete } from "@lloyds/ui-connected";
import { ActionButton, RadioGroup } from "@lloyds/ui-core";
import { Box, Grid, IconButton, Typography } from "@material-ui/core";

import styles from "./DesktopFlightSearch.module.scss";

export const DesktopFlightSearch = () => {
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  const {
    context: {
      tripCategory,
      passengerCounts,
      origin,
      destination,
      departureDate,
      returnDate,
      validSearchParams,
    },
    handlers: {
      onDepartureDateChange,
      onDestinationChange,
      onOriginChange,
      onPassengerCountChange,
      onReturnDateChange,
      onTripCategoryChange,
      onSearch,
      onRecentSearchClick,
    },
  } = useFlightSearch();

  const [rotation, setRotation] = useState<number>(0);
  const location = useLocation();

  const tripOptions = [
    { label: t("flightRoundTrip"), value: TripCategory.ROUND_TRIP },
    { label: t("flightOneWay"), value: TripCategory.ONE_WAY },
  ];
  const isReadyToSearch = useMemo(() => {
    return tripCategory === TripCategory.ONE_WAY
      ? !!departureDate && !!origin
      : tripCategory === TripCategory.ROUND_TRIP
      ? !!returnDate && !!departureDate && !!origin && !!destination
      : false;
  }, [tripCategory, destination, origin, returnDate, departureDate]);

  const handleSearch = useCallback(() => {
    onSearch(validSearchParams);
  }, [validSearchParams, onSearch]);

  const handleSwitch = useCallback(() => {
    onOriginChange(destination);
    onDestinationChange(origin);
    setRotation((prevRotation) => prevRotation + 180);
  }, [onOriginChange, onDestinationChange, setRotation, destination, origin]);

  const { departureMonths, priceTags } = FlightSearchHooks.useFetchCalendar({
    origin,
    destination,
    tripCategory,
  });

  const isSearchRoute = useMemo(() => {
    return location.pathname.includes(FLIGHT_ROUTES.SEARCH_ROUTE);
  }, [location]);

  const { recentSearches, filterByOrigin, filterByDestination } =
    useFlightRecentSearches();

  return (
    <>
      {isSearchRoute && (
        <Box marginBottom={4} marginTop={4}>
          <Typography variant="h3">
            {t("flightsPageTitles.searchFlights")}
          </Typography>
        </Box>
      )}

      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="stretch"
        className={styles.container}
      >
        <Grid item xs={3}>
          <RadioGroup
            value={tripCategory}
            options={tripOptions}
            onChange={onTripCategoryChange}
            className={styles.radioGroup}
            containerClassName={styles.radioInputContainer}
            radioClassName={styles.radioLabel}
          />
        </Grid>
        <Grid item xs={3}>
          <PassengerPickerInput
            paxCounts={passengerCounts}
            updatePaxCounts={onPassengerCountChange}
          />
        </Grid>
        <Grid item xs={6}></Grid>

        <Grid item xs={3}>
          <FlightLocationAutoComplete
            id="origin"
            label=""
            placeholder={t("searchControl.whereFrom")}
            recentSearches={recentSearches}
            filterRecentSearches={filterByOrigin}
            onRecentSearchClick={onRecentSearchClick}
            onChange={(sug) => onOriginChange(suggestionToITripTerminus(sug))}
            defaultValue={origin && terminusToSuggestion(origin)}
            className={styles.airAutocomplete}
            icon={
              <img
                src={icons.locationMarker}
                alt=""
                className={styles.autocompleteIcon}
              />
            }
            additionalSearchControl={{
              activeControl: "origin",
              destination: origin?.id?.code?.code ?? undefined,
            }}
          />
        </Grid>
        <Grid item xs={3}>
          <FlightLocationAutoComplete
            id="destination"
            label=""
            placeholder={t("searchControl.whereTo")}
            recentSearches={recentSearches}
            filterRecentSearches={filterByDestination}
            onRecentSearchClick={onRecentSearchClick}
            onChange={(sug) =>
              onDestinationChange(suggestionToITripTerminus(sug))
            }
            defaultValue={destination && terminusToSuggestion(destination)}
            className={styles.airAutocomplete}
            icon={<img src={icons.locationMarker} alt="" />}
            additionalSearchControl={{
              activeControl: "destination",
              destination: destination?.id?.code?.code ?? undefined,
            }}
          />
        </Grid>
        <Grid item xs={tripCategory === TripCategory.ONE_WAY ? 3 : 6}>
          <CalendarPickerButton
            startDate={departureDate ?? null}
            endDate={returnDate ?? null}
            tripCategory={tripCategory}
            startLabel={t("departure") ?? undefined}
            endLabel={t("return") ?? undefined}
            setStartDate={onDepartureDateChange}
            setEndDate={onReturnDateChange}
            CalendarComponent={FlightCalendarPicker}
            priceTags={priceTags}
            departureMonths={departureMonths}
          />
        </Grid>
        <Grid item xs={2}>
          <Box height="100%" display={"flex"}>
            <ActionButton
              onClick={handleSearch}
              disabled={!isReadyToSearch}
              message={t("searchButton")}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
