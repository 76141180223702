import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import type { Suggestion } from "@b2bportal/lodging-api";
import { useIsRoomsEnabled } from "@hopper-b2b/common-search";
import type { SearchLodgingProps } from "@hopper-b2b/lodging-availability";
import {
  buildSearchParams,
  placeLabelAndSublabelToUrl,
} from "@hopper-b2b/lodging-utils";
import type { GuestsSelection } from "@hopper-b2b/types";
import {
  PATH_HOME,
  PATH_HOTELS_BOOK_AVAILABILITY,
} from "@hopper-b2b/utilities";
import type { HotelSearchQueryArgs } from "@lloyds/utils";

import { saveRecentSearch } from "./useLodgingRecentSearch";

export const initialLodgingSearchState = {
  adultGuests: 2,
  childGuests: [],
  rooms: 1,
};

export type HotelSearchProps = SearchLodgingProps & { onComplete?: () => void };

export const useHotelSearch = ({
  initialCheckoutDate,
  initialGuestCount,
  initialCheckinDate,
  initialDestination,
  onComplete,
  onSearch,
}: Partial<HotelSearchProps> = {}) => {
  const enableRooms = useIsRoomsEnabled();

  const navigate = useNavigate();

  const [destination, setDestination] = useState<Suggestion | undefined>(
    initialDestination
  );
  const [checkinDate, setCheckinDate] = useState<string | undefined>(
    initialCheckinDate
  );
  const [checkoutDate, setCheckoutDate] = useState<string | undefined>(
    initialCheckoutDate
  );
  const [guestCount, setGuestCount] = useState<GuestsSelection>(
    initialGuestCount ?? {
      adults: initialLodgingSearchState.adultGuests,
      children: initialLodgingSearchState.childGuests,
      rooms: initialLodgingSearchState.rooms,
    }
  );

  const placeId = useMemo(
    () =>
      destination?.id?.Id === "Lodgings" &&
      destination?.id?.lodgingSelection.LodgingSelection === "Place" &&
      destination?.id?.lodgingSelection.placeId,
    [destination]
  );

  const query = useMemo(
    () =>
      destination?.id?.Id === "Lodgings" &&
      destination?.id?.lodgingSelection.LodgingSelection === "Place" &&
      destination?.id?.lodgingSelection.searchTerm,
    [destination]
  );

  useEffect(() => {
    setDestination(initialDestination);
  }, [initialDestination]);

  const destinationCode = useMemo(() => {
    if (destination) {
      const { label, subLabel } = destination;
      return placeLabelAndSublabelToUrl(label, subLabel);
    }
    return undefined;
  }, [destination]);

  const isSearchReady = useMemo(
    () => destination && checkinDate && checkoutDate,
    [checkinDate, checkoutDate, destination]
  );

  const handleSearch = useCallback(() => {
    const searchParams = buildSearchParams({
      fromDate: checkinDate,
      untilDate: checkoutDate,
      adultsCount: guestCount.adults,
      childrenCount: guestCount.children,
      roomsCount: guestCount.rooms,
      enableRooms,
      placeId,
      query,
    });

    saveRecentSearch({
      ...destination,
      fromDate: checkinDate,
      untilDate: checkoutDate,
      adultsCount: guestCount.adults,
      childrenCount: guestCount.children,
      roomsCount: guestCount.rooms,
      enableRooms,
      placeId,
      query,
    });

    if (onSearch) {
      onSearch({
        nextDestination: destination,
        nextFromDate: checkinDate,
        nextUntilDate: checkoutDate,
        nextAdultsCount: guestCount?.adults ?? 2,
        nextChildrenCount: guestCount?.children?.length ?? 0,
        nextChildrenAges: guestCount?.children ?? [],
        nextRoomsCount: guestCount?.rooms ?? 1,
      });
    } else {
      navigate(
        `${PATH_HOME}${PATH_HOTELS_BOOK_AVAILABILITY}${destinationCode}?${searchParams.toString()}`
      );
    }
    onComplete?.();
  }, [
    checkinDate,
    checkoutDate,
    guestCount.adults,
    guestCount.children,
    guestCount.rooms,
    enableRooms,
    placeId,
    query,
    destination,
    onSearch,
    onComplete,
    navigate,
    destinationCode,
  ]);

  const onRecentSearchClick = useCallback(
    (recentSearch: HotelSearchQueryArgs) => {
      const searchParams = buildSearchParams(recentSearch);
      saveRecentSearch(recentSearch);

      if (onSearch) {
        onSearch({
          nextDestination: recentSearch,
          nextFromDate: recentSearch.fromDate,
          nextUntilDate: recentSearch.untilDate,
          nextAdultsCount: recentSearch.adultsCount,
          nextChildrenCount: recentSearch.childrenCount.length,
          nextChildrenAges: recentSearch.childrenCount,
          nextRoomsCount: recentSearch.roomsCount,
        });
      } else {
        navigate(
          `${PATH_HOME}${PATH_HOTELS_BOOK_AVAILABILITY}${placeLabelAndSublabelToUrl(
            recentSearch.label,
            recentSearch.subLabel
          )}?${searchParams.toString()}`
        );
      }

      onComplete?.();
    },
    [navigate, onSearch, onComplete]
  );

  const resetSearch = useCallback(() => {
    setCheckinDate(initialCheckinDate);
    setCheckoutDate(initialCheckoutDate);
    setDestination(initialDestination);
    setGuestCount(initialGuestCount);
  }, [
    initialGuestCount,
    initialCheckoutDate,
    initialDestination,
    initialCheckinDate,
  ]);

  return {
    context: {
      destination,
      destinationCode,
      checkinDate,
      checkoutDate,
      guestCount,
      isSearchReady,
    },
    handlers: {
      setDestination,
      setGuestCount,
      setCheckinDate,
      setCheckoutDate,
      handleSearch,
      onRecentSearchClick,
      resetSearch,
    },
  };
};
