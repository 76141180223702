import type { ReactNode } from "react";
import { Box, Typography } from "@material-ui/core";

import clsx from "clsx";

import styles from "./RadioGroup.module.scss";
import { RadioInput } from "./RadioInput";

export const RadioGroup = ({
  value,
  onChange,
  options,
  className,
  containerClassName,
  radioClassName,
}: {
  value: string;
  options: { label: ReactNode; value: string }[];
  onChange: (val: string) => void;
  className?: string;
  containerClassName?: string;
  radioClassName?: string;
}) => {
  return (
    <div className={className}>
      {options.map((option, idx) => (
        <Box
          paddingY={"8px"}
          onClick={() => onChange(option.value)}
          key={idx}
          display="flex"
          gridGap={12}
          alignItems="center"
          className={clsx(styles.radioInputContainer, containerClassName)}
        >
          <RadioInput
            className={radioClassName}
            checked={value === option.value}
          />
          <Typography
            className={clsx(styles.radioLabel, radioClassName)}
            variant="inherit"
          >
            {option.label}
          </Typography>
        </Box>
      ))}
    </div>
  );
};
