import { useMemo } from "react";
import { useLocation } from "react-router";
import { PATH_HOME, PATH_TRIPS, PATH_WALLET } from "@hopper-b2b/utilities";
import { HeaderUI } from "@lloyds/ui-connected";
import { useIsBookingConfirmationPath } from "@lloyds/utils";

import { WalletSummary } from "@lloyds/wallet";
import { HeaderLogo, NavigationLinks } from "../components";

export const DesktopHeader = () => {
  const { pathname } = useLocation();

  const isBookingConfirmation = useIsBookingConfirmationPath();

  const showNavigation = useMemo(
    () =>
      pathname === PATH_HOME ||
      pathname === PATH_TRIPS ||
      pathname === PATH_WALLET ||
      isBookingConfirmation,
    [pathname, isBookingConfirmation]
  );

  return (
    <HeaderUI
      leftItem={<HeaderLogo />}
      centerItem={showNavigation && <NavigationLinks />}
      rightItem={<WalletSummary />}
    />
  );
};
