import { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import { useTenantIcons } from "@hopper-b2b/utilities";
import { TextInputField } from "@lloyds/ui-connected";
import { InputAdornment } from "@material-ui/core";

export const SearchButton = ({
  path,
  placeholder,
}: {
  path: string;
  placeholder: string;
}) => {
  const navigate = useNavigate();
  const icons = useTenantIcons();

  const handleSearch = useCallback(() => {
    navigate(path);
  }, [navigate, path]);

  return (
    <TextInputField
      clearable={false}
      placeholder={placeholder}
      onClick={handleSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <img src={icons.search} alt="search-hotels" />
          </InputAdornment>
        ),
        readOnly: true,
      }}
    />
  );
};
