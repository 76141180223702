import { useI18nContext } from "@hopper-b2b/i18n";
import { useTenantIcons } from "@hopper-b2b/utilities";
import {
  GuestPickerInput,
  CalendarPickerButton,
  HotelCalendarPicker,
} from "@lloyds/ui-connected";
import { HotelLocationAutoComplete } from "@lloyds/ui-connected";
import { ActionButton } from "@lloyds/ui-core";
import { Box, Grid } from "@material-ui/core";

import { useHotelSearch } from "../../hooks";
import { useHotelsRecentSearches } from "../../hooks/useLodgingRecentSearch";
import styles from "./DesktopHotelSearch.module.scss";

export const DesktopHotelSearch = () => {
  const { t } = useI18nContext();
  const icons = useTenantIcons();

  const {
    context: {
      destination,
      checkinDate,
      checkoutDate,
      guestCount,
      isSearchReady,
    },
    handlers: {
      setCheckoutDate,
      setCheckinDate,
      setGuestCount,
      setDestination,
      handleSearch,
      onRecentSearchClick,
    },
  } = useHotelSearch();

  const { recentSearches, filterByDestination } = useHotelsRecentSearches();

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      alignItems="stretch"
      className={styles.container}
    >
      <Grid item xs={4}>
        <HotelLocationAutoComplete
          id="destination"
          label=""
          onChange={setDestination}
          recentSearches={recentSearches}
          filterRecentSearches={filterByDestination}
          onRecentSearchClick={onRecentSearchClick}
          placeholder={t("lodging.search.whereAreYouStaying")}
          defaultValue={destination}
          closeIcon={null}
          icon={
            <img
              src={icons.locationMarker}
              alt=""
              className={styles.autocompleteIcon}
            />
          }
        />
      </Grid>
      <Grid item xs={2}>
        <GuestPickerInput
          guestCounts={guestCount}
          updateGuestCounts={setGuestCount}
        />
      </Grid>
      <Grid item xs={6}>
        <CalendarPickerButton
          startDate={checkinDate ?? null}
          endDate={checkoutDate ?? null}
          startLabel={t("checkin") ?? undefined}
          endLabel={t("checkout") ?? undefined}
          setStartDate={setCheckinDate}
          setEndDate={setCheckoutDate}
          CalendarComponent={HotelCalendarPicker}
        />
      </Grid>
      <Grid item lg={2} className="hotel-search-button-container">
        <Box height="100%" display={"flex"}>
          <ActionButton
            onClick={handleSearch}
            disabled={!isSearchReady}
            message={t("searchButton")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};
