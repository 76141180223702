import { useI18nContext } from "@hopper-b2b/i18n";
import { Box, Grid, Typography } from "@material-ui/core";

import { ReactComponent as Person } from "./person.svg";
import styles from "./TravelerSelectRowEmpty.module.scss";

export const TravelerSelectRowEmpty = () => {
  const { t } = useI18nContext();

  return (
    <Grid container spacing={4} direction="row" alignItems="center">
      <Grid item>
        <Box className={styles.icon}>
          <Person />
        </Box>
      </Grid>
      <Grid item xs>
        <Typography variant="h5" gutterBottom>
          {t("emptyPassengersTitle")}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {t("emptyPassengersDescription")}
        </Typography>
      </Grid>
    </Grid>
  );
};
