import { useState } from "react";
import type { FiatPrice } from "@b2bportal/air-shopping-api";
import { useRoundedFiatPriceToText } from "@b2bportal/core-utilities";
import type { FilterPriceValue } from "@components/ui";
import { useI18nContext } from "@hopper-b2b/i18n";
import { debounce } from "lodash-es";

export type UseFilterPriceProps = {
  minimumPrice: FiatPrice;
  maximumPrice: FiatPrice;
  initialValue: FilterPriceValue;
  isMinValueConfigurable: boolean;
  onFilterPriceChange: (value: FilterPriceValue) => void;
  fallbackCurrency: Omit<FiatPrice, "value">;
};

export const useFilterPrice = ({
  minimumPrice,
  maximumPrice,
  initialValue,
  onFilterPriceChange,
  isMinValueConfigurable,
  fallbackCurrency,
}: UseFilterPriceProps) => {
  const { t } = useI18nContext();

  const { minPrice, maxPrice } = initialValue;

  const initialMinPrice = Math.max(minPrice, minimumPrice.value);
  const initialMaxPrice = maximumPrice.value
    ? Math.min(maxPrice, maximumPrice.value)
    : maxPrice;

  const [selectedValues, setSelectedValues] = useState({
    minPrice: initialMinPrice,
    maxPrice: initialMaxPrice,
  });

  const validateValues = ({ minPrice, maxPrice }: FilterPriceValue) =>
    minPrice <= maxPrice;

  const debouncedAction = debounce(onFilterPriceChange, 300);

  const [stateDebounceDispatchAction] = useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const getSliderValue = () => {
    if (isMinValueConfigurable) {
      return [selectedValues.minPrice, selectedValues.maxPrice];
    }
    return [selectedValues.maxPrice];
  };

  const fiatPriceToText = useRoundedFiatPriceToText();
  const formatValueLabel = (value: number): string =>
    maximumPrice.currencyCode
      ? fiatPriceToText({ ...maximumPrice, value })
      : value.toString();

  const numberToFiatPrice = (number: number) => {
    const currency = maximumPrice?.currencyCode
      ? maximumPrice
      : fallbackCurrency;
    return {
      ...currency,
      value: number,
    };
  };

  const getValuesFromSlider = (newPrices: number[]) => {
    const maxPrice = newPrices.length === 2 ? newPrices[1] : newPrices[0];
    const minPrice = newPrices.length === 2 ? newPrices[0] : 0;
    return { maxPrice, minPrice };
  };

  const handleInputChange = (selectedValues: FilterPriceValue) => {
    setSelectedValues(selectedValues);
    onFilterPriceChange(selectedValues);
  };

  const handleSliderChange = (values: number[]) => {
    const filterValues = getValuesFromSlider(values);
    if (!validateValues(filterValues)) {
      return;
    }
    setSelectedValues(filterValues);
    stateDebounceDispatchAction(filterValues);
  };

  const handleMinPriceInputChange = (newMinPrice: FiatPrice) => {
    if (newMinPrice.value !== selectedValues.minPrice) {
      handleInputChange({ ...selectedValues, minPrice: newMinPrice.value });
    }
  };

  const handleMaxPriceInputChange = (newMaxPrice: FiatPrice) => {
    if (newMaxPrice.value !== selectedValues.maxPrice) {
      handleInputChange({ ...selectedValues, maxPrice: newMaxPrice.value });
    }
  };

  const text = {
    textField: {
      min: t("core-ui.filters.price.min"),
      max: t("core-ui.filters.price.max"),
    },
  };

  return {
    text,
    context: {
      sliderValue: getSliderValue(),
      textField: {
        min: {
          min: minimumPrice.value,
          max: selectedValues.maxPrice,
          value: numberToFiatPrice(selectedValues.minPrice),
          fallbackValue: minimumPrice.value,
        },
        max: {
          min: Math.max(minimumPrice.value, selectedValues.minPrice),
          max: maximumPrice.value,
          value: numberToFiatPrice(selectedValues.maxPrice),
          fallbackValue: maximumPrice.value,
        },
      },
    },
    handlers: {
      onChangeMaxTextField: handleMaxPriceInputChange,
      onChangeMinTextField: handleMinPriceInputChange,
      formatValueLabel,
      onSliderChange: handleSliderChange,
    },
  };
};
